var Routes={};
function buildRoute(method_name, route) {
    Routes[method_name] = function () {
        var i;
        var splits = route.split('/'), args = Array.prototype.slice.call(arguments);
        for (i = 0; i < splits.length; i++) {
            if (splits[i].indexOf(':') === 0) {
                var param = {value: args.shift(), name: splits[i].slice(1)};
                if (!param.value) {
                    throw 'Parameter \'' + param.name + '\' cannot be empty in \'' + method_name + '\'';
                } else if (typeof param.value === 'object') {
                    var origParamValue = param.value;
                    param.value = origParamValue.id;
                    if (typeof param.value === 'undefined') {
                        param.value = origParamValue[param.name];
                    }
                    if (typeof param.value === 'function') {
                        param.value = param.value.call(origParamValue);
                    }
                    if (typeof param.value === 'undefined') {
                        param.value = origParamValue.toString();
                    }
                } else if (typeof param.value === 'function') {
                    param.value = param.value.call(param.value);
                }
                splits[i] = param.value;
            }
        }

        var urlParams='';
        var obj = args.shift();
        if (typeof obj === 'object' && !!obj) {
            urlParams += '?';
            Object.keys(obj).forEach(function(k) {
                if (obj[k] === undefined || obj[k] === null) {
                    return;
                }
                urlParams += encodeURIComponent(k);
                urlParams += '=';
                urlParams += encodeURIComponent(obj[k]);
                urlParams += '&';
            });
        }

        return splits.join('/') + urlParams.slice(0, -1);
    };

    Object.defineProperty(Routes[method_name], 'name', {value: method_name, writable: false});
}

buildRoute('print_path','/print');
buildRoute('email_preview_path','/email_preview');
buildRoute('get_referrer_data_path','/api/referrer_marketing');
buildRoute('get_aws_policy_path','/application/get_aws_policy');
buildRoute('validate_onboarding_path','/api/validate_onboarding');
buildRoute('gon_path','/api/gon');
buildRoute('malkut_graphql_path','/api/graphql/malkut');
buildRoute('v1_new_inquiry_redirect_path','/api/v1/couple_cards/widget_create_user_and_workspace');
buildRoute('v2_new_inquiry_redirect_path','/api/v2/couple_cards/widget_create_user_and_workspace');
buildRoute('v2_client_cache_log_event_path','/api/v2/client_cache/log_event');
buildRoute('v2_search_services_unified_path','/api/v2/search/:user_id/items');
buildRoute('v2_delete_services_path','/api/v2/services/:id');
buildRoute('v2_search_user_es_path','/api/v2/search/user/:term');
buildRoute('v2_workspaces_pipeline_path','/api/v2/search/pipeline');
buildRoute('v2_company_workspace_counts_path','/api/v2/search/company_pipeline');
buildRoute('v2_get_recently_sent_flows_path','/api/v2/search/flows/recently_sent');
buildRoute('v2_get_recently_sent_files_and_flows_path','/api/v2/search/flows/recently_sent_files_and_flows');
buildRoute('v2_get_most_used_flows_path','/api/v2/search/flows/most_used');
buildRoute('v2_get_templates_path','/api/v2/search/home/templates');
buildRoute('v2_list_company_flows_path','/api/v2/search/company/:company_id/flows');
buildRoute('v2_search_all_items_path','/api/v2/search/search_all/:entities');
buildRoute('v2_search_items_path','/api/v2/search/:entities/:term');
buildRoute('v2_search_items_with_body_path','/api/v2/search/:entities');
buildRoute('v2_get_recently_sent_flows_old_path','/api/v2/flows/recently_sent');
buildRoute('v2_get_recently_sent_files_and_flows_old_path','/api/v2/flows/recently_sent_files_and_flows');
buildRoute('v2_get_templates_old_path','/api/v2/home/templates');
buildRoute('v2_get_most_used_flows_old_path','/api/v2/flows/most_used');
buildRoute('v2_list_company_flows_old_path','/api/v2/company/:company_id/flows');
buildRoute('v2_search_user_es_old_path','/api/v2/search_users/:term');
buildRoute('v2_workspaces_pipeline_old_path','/api/v2/workspaces/pipeline');
buildRoute('v2_company_workspace_counts_old_path','/api/v2/workspaces/company_pipeline');
buildRoute('v2_search_all_items_old_path','/api/v2/search_all/:entities');
buildRoute('v2_get_careers_path','/api/v2/careers/get_careers');
buildRoute('v2_automations_get_workflows_path','/api/v2/automations/workflows');
buildRoute('v2_automations_get_workflow_path','/api/v2/automations/workflows/:workflow_id');
buildRoute('v2_automations_update_workflow_path','/api/v2/automations/workflows/:workflow_id');
buildRoute('v2_automations_create_workflow_path','/api/v2/automations/workflows');
buildRoute('v2_automations_delete_workflow_path','/api/v2/automations/workflows/:workflow_id');
buildRoute('v2_automations_create_workflow_linked_entities_path','/api/v2/automations/linked_entities/:workflow_id');
buildRoute('v2_automations_update_workflow_linked_entities_path','/api/v2/automations/linked_entities/:workflow_id');
buildRoute('v2_automations_delete_workflow_linked_entities_path','/api/v2/automations/linked_entities/:workflow_id');
buildRoute('v2_automations_create_execution_events_path','/api/v2/automations/executions/:executionId/events');
buildRoute('v2_automations_get_dynamic_node_parameter_options_path','/api/v2/automations/dynamic_node_parameter_options');
buildRoute('v2_automations_get_batch_workflow_statistics_path','/api/v2/automations/batch_workflow_statistics/counts');
buildRoute('v2_automations_get_batch_workflow_statistics_execution_counts_path','/api/v2/automations/batch_workflow_statistics/execution_counts');
buildRoute('v2_automations_get_executions_path','/api/v2/automations/executions');
buildRoute('v2_automations_approve_execution_path','/api/v2/automations/executions/:execution_id/approve');
buildRoute('v2_automations_get_execution_path','/api/v2/automations/executions/:execution_id');
buildRoute('v2_automations_stop_execution_path','/api/v2/automations/executions/:execution_id/stop');
buildRoute('v2_automations_delete_executions_path','/api/v2/automations/executions/delete');
buildRoute('v2_automations_get_executions_data_path','/api/v2/automations/executions_data');
buildRoute('v2_automations_get_credentials_path','/api/v2/automations/credentials');
buildRoute('v2_automations_create_approval_subscription_path','/api/v2/automations/approval_subscriptions');
buildRoute('v2_automations_create_execution_subscription_path','/api/v2/automations/execution_subscriptions');
buildRoute('v2_automations_create_timer_subscription_path','/api/v2/automations/timer_subscriptions');
buildRoute('v2_automations_create_subscription_path','/api/v2/automations/subscriptions');
buildRoute('v2_automations_delete_subscription_path','/api/v2/automations/subscriptions/:subscription_id');
buildRoute('v2_automations_get_subscription_path','/api/v2/automations/subscriptions/:subscription_id');
buildRoute('v2_automations_move_pipeline_stage_path','/api/v2/automations/actions/move_pipeline_stage');
buildRoute('v2_automations_send_email_path','/api/v2/automations/actions/send_email');
buildRoute('v2_automations_send_flow_via_email_path','/api/v2/automations/actions/send_flow_via_email');
buildRoute('v2_automations_create_task_path','/api/v2/automations/actions/create_task');
buildRoute('v2_automations_create_project_path','/api/v2/automations/actions/create_project');
buildRoute('v2_automations_get_node_types_path','/api/v2/automations/types/nodes');
buildRoute('v2_automations_get_credential_types_path','/api/v2/automations/types/credentials');
buildRoute('v2_automations_hooks_path','/api/v2/automations/n8n_hooks');
buildRoute('v2_automations_get_credential_for_integration_path','/api/v2/automations/n8n/credentials');
buildRoute('v2_create_new_company_path','/api/v2/account/:account_id/company');
buildRoute('v2_delete_company_path','/api/v2/account/:account_id/company/:company_id');
buildRoute('v2_list_account_companies_path','/api/v2/account/:account_id/companies');
buildRoute('v2_get_account_transaction_fees_without_discounts_path','/api/v2/account/:account_id/transaction_fees');
buildRoute('v2_update_account_settings_path','/api/v2/account/:account_id/settings');
buildRoute('v2_update_account_setup_state_path','/api/v2/account/:account_id/account_setup_state');
buildRoute('v2_complete_manual_account_setup_path','/api/v2/account/:account_id/manual_account_setup');
buildRoute('v2_set_card_on_file_path','/api/v2/account/:account_id/settings/card_on_file');
buildRoute('v2_active_recurring_payment_files_path','/api/v2/account/:account_id/active_recurring_payment_files');
buildRoute('v2_is_n8n_enabled_path','/api/v2/account/:account_id/is_n8n_enabled');
buildRoute('v2_get_home_calendar_path','/api/v2/home/calendar');
buildRoute('v2_get_home_bookkeeping_path','/api/v2/home/bookkeeping');
buildRoute('v2_get_home_recent_activity_path','/api/v2/home/recent_activity');
buildRoute('v2_get_home_structure_path','/api/v2/home/home_structure');
buildRoute('v2_get_one_home_data_path','/api/v2/home/one_home_data');
buildRoute('v2_get_meetings_path','/api/v2/home/meeting');
buildRoute('v2_get_meeting_for_mobile_path','/api/v2/home/meetings_for_mobile');
buildRoute('v2_get_recent_activities_path','/api/v2/home/recent_activities');
buildRoute('v2_get_clients_path','/api/v2/home/clients');
buildRoute('v2_get_leads_path','/api/v2/home/leads');
buildRoute('v2_get_unread_messages_path','/api/v2/home/unread_messages');
buildRoute('v2_get_resources_path','/api/v2/home/resources');
buildRoute('v2_get_tasks_path','/api/v2/home/tasks');
buildRoute('v2_get_payments_path','/api/v2/home/payments');
buildRoute('v2_get_tasks_for_user_optimized_path','/api/v2/tasks_optimized');
buildRoute('v2_get_tasks_for_user_path','/api/v2/tasks');
buildRoute('v2_get_tasks_for_workspace_path','/api/v2/tasks/workspaces/:workspace_id');
buildRoute('v2_get_tasks_counts_for_user_path','/api/v2/tasks/user/counts');
buildRoute('v2_get_tasks_counts_for_workspace_path','/api/v2/tasks/workspaces/:workspace_id/counts');
buildRoute('v2_get_task_email_path','/api/v2/tasks/:task_id/email');
buildRoute('v2_create_task_path','/api/v2/tasks');
buildRoute('v2_update_task_path','/api/v2/tasks/:task_id');
buildRoute('v2_delete_task_path','/api/v2/tasks/:task_id');
buildRoute('v2_get_template_workflows_as_text_path','/api/v2/company/:company_id/workflows/template_workflows_as_text');
buildRoute('v2_get_incomplete_workflows_report_path','/api/v2/company/:company_id/workflows/incomplete_workflows_report');
buildRoute('v2_get_workflows_for_company_path','/api/v2/company/:company_id/workflows');
buildRoute('v2_get_workflow_path','/api/v2/company/:company_id/workflows/:workflow_id');
buildRoute('v2_get_flows_with_contract_path','/api/v2/company/:company_id/workflows/:workflow_id/flows_with_contract');
buildRoute('v2_create_workflow_path','/api/v2/company/:company_id/workflows/');
buildRoute('v2_duplicate_workflow_path','/api/v2/company/:company_id/workflows/:workflow_id/duplicate');
buildRoute('v2_update_workflow_path','/api/v2/company/:company_id/workflows/:workflow_id');
buildRoute('v2_update_workflow_project_type_path','/api/v2/company/:company_id/workflows/:workflow_id/project_type');
buildRoute('v2_update_workflow_to_lead_forms_path','/api/v2/company/:company_id/workflows/:workflow_id/public_files');
buildRoute('v2_delete_workflow_path','/api/v2/company/:company_id/workflows/:workflow_id');
buildRoute('v2_get_project_time_trackings_path','/api/v2/time_trackings/company/:company_id/project/:project_id');
buildRoute('v2_get_company_time_trackings_path','/api/v2/time_trackings/company/:company_id');
buildRoute('v2_create_time_tracking_path','/api/v2/time_trackings/:company_id');
buildRoute('v2_download_time_tracking_csv_path','/api/v2/time_trackings/csv/:company_id');
buildRoute('v2_create_file_from_time_tracking_path','/api/v2/time_trackings/project/:project_id/file');
buildRoute('v2_create_flow_from_time_tracking_path','/api/v2/time_trackings/project/:project_id/flow');
buildRoute('v2_update_time_tracking_path','/api/v2/time_trackings/:time_tracking_id');
buildRoute('v2_delete_time_tracking_path','/api/v2/time_trackings/:time_tracking_id');
buildRoute('v2_validate_email_path','/api/v2/validate/email/:email');
buildRoute('v2_validate_email_for_registration_path','/api/v2/users/validations/emails/:email');
buildRoute('v2_validate_email_new_path','/api/v2/validate/email/');
buildRoute('v2_validate_email_for_registration_new_path','/api/v2/users/validations/emails');
buildRoute('v2_validate_contact_form_email_path','/api/v2/email/validate');
buildRoute('v2_get_pro_authorizations_path','/api/v2/pro-authorizations');
buildRoute('v2_get_pro_auth_statuses_count_path','/api/v2/pro-authorizations/statuses-count');
buildRoute('v2_pro_request_auth_path','/api/v2/pro-authorizations');
buildRoute('v2_pro_resend_auth_request_path','/api/v2/pro-authorizations/:id/resend');
buildRoute('v2_pro_request_extension_path','/api/v2/pro-authorizations/:id/extend');
buildRoute('v2_pro_archive_auth_path','/api/v2/pro-authorizations/:id/archive');
buildRoute('v2_vendor_approve_pro_access_path','/api/v2/pro-authorizations/:id/approve');
buildRoute('v2_vendor_revoke_pro_access_path','/api/v2/pro-authorizations/:id/revoke');
buildRoute('v2_get_bookkeeper_authorizations_path','/api/v2/bookkeeper-authorizations');
buildRoute('v2_vendor_extend_bookkeeper_access_path','/api/v2/bookkeeper-authorizations/:id/extend');
buildRoute('v2_vendor_revoke_bookkeeper_access_path','/api/v2/bookkeeper-authorizations/:id/revoke');
buildRoute('v2_user_logout_new_path','/api/v2/users/tokens');
buildRoute('v2_user_login_new_path','/api/v2/users/tokens');
buildRoute('v2_user_fake_login_path','/api/v2/users/fake_login');
buildRoute('v2_user_login_with_facebook_path','/api/v2/users/oauth/facebook');
buildRoute('v2_user_login_with_google_path','/api/v2/users/oauth/google');
buildRoute('v2_sso_signup_user_path','/api/v2/users/oauth/sso_signup_user');
buildRoute('v2_sso_login_user_path','/api/v2/users/oauth/sso_login_user');
buildRoute('v2_vendor_login_as_client_path','/api/v2/users/:workspace_file_id/login_as_client');
buildRoute('v2_vendor_logout_as_client_path','/api/v2/users/:workspace_file_id/logout_as_client');
buildRoute('v2_user_calendly_scheduled_endpoint_path','/api/v2/users/user_calendly_scheduled');
buildRoute('v2_invite_existing_member_path','/api/v2/company/:id/invite_member');
buildRoute('v2_check_invite_existing_member_path','/api/v2/company/:id/check_invite_member');
buildRoute('v2_decline_pending_invite_path','/api/v2/user/:user_id/decline_pending_invite');
buildRoute('v2_cancel_pending_invite_path','/api/v2/user/:user_id/cancel_pending_invite');
buildRoute('v2_get_user_pending_invite_path','/api/v2/user/:id/get_user_pending_invite');
buildRoute('v2_user_community_token_path','/api/v2/users/community_token');
buildRoute('v2_user_fullstory_token_path','/api/v2/users/fullstory_token');
buildRoute('v2_pro_login_start_mfa_path','/api/v2/users/pro/start-mfa');
buildRoute('v2_pro_login_as_vendor_path','/api/v2/users/pro/login/:user_id');
buildRoute('v2_pro_logout_as_vendor_path','/api/v2/users/pro/logout');
buildRoute('v2_instant_payment_preview_path','/api/v2/instant-pay/preview');
buildRoute('v2_instant_payment_load_path','/api/v2/instant-pay/load');
buildRoute('v2_instant_payment_pay_path','/api/v2/instant-pay/pay');
buildRoute('v2_user_leads_capture_enabled_path','/api/v2/users/leads_capture_enabled');
buildRoute('v2_user_update_leads_capture_enabled_path','/api/v2/users/leads_capture_enabled');
buildRoute('v2_create_user_path','/api/v2/users');
buildRoute('v2_delete_user__dont_use_without_checking_with_scale_team_path','/api/v2/users/:id');
buildRoute('v2_create_bank_account_path','/api/v2/users/:id/bank_account');
buildRoute('v2_create_chargeable_bank_account_path','/api/v2/users/:id/chargeable_bank_account');
buildRoute('v2_create_payment_method_path','/api/v2/users/:id/payment_methods');
buildRoute('v2_delete_bank_account_owner_path','/api/v2/companies/:id/stripe_person/:payment_person_id');
buildRoute('v2_save_verification_document_path','/api/v2/companies/:id/verification_document');
buildRoute('v2_add_user_device_path','/api/v2/users/:id/devices');
buildRoute('v2_track_iterable_push_opened_path','/api/v2/users/:id/track_iterable_push_opened');
buildRoute('v2_send_client_login_code_path','/api/v2/users/:id/verification');
buildRoute('v2_verify_client_login_code_path','/api/v2/users/:id/verification');
buildRoute('v2_add_trial_path','/api/v2/users/:id/add_trial');
buildRoute('v2_check_extend_trial_path','/api/v2/users/:id/extend_trial/check');
buildRoute('v2_request_extend_trial_path','/api/v2/users/:id/extend_trial/request');
buildRoute('v2_add_user_webpn_endpoint_path','/api/v2/users/:id/add_webpn');
buildRoute('v2_remove_user_webpn_endpoint_path','/api/v2/users/:id/remove_webpn');
buildRoute('v2_start_phone_verification_path','/api/v2/users/:id/phone_verification');
buildRoute('v2_start_2fa_by_email_path','/api/v2/users/:id/2fa_verification_by_email');
buildRoute('v2_validate_phone_verification_path','/api/v2/users/:id/validate_phone_verification');
buildRoute('v2_send_email_verification_path','/api/v2/users/:id/send_email_verification');
buildRoute('v2_dismiss_loan_offer_path','/api/v2/users/:id/dismiss_loan_offer');
buildRoute('v2_validate_user_credit_card_expiration_date_path','/api/v2/users/validations/credit_card/date/:payment_method_id');
buildRoute('v2_plaid_create_link_token_path','/api/v2/users/:id/plaid/create_link_token');
buildRoute('v2_plaid_exchange_public_token_path','/api/v2/users/:id/plaid/exchange_public_token');
buildRoute('v2_report_plaid_event_path','/api/v2/users/:id/plaid/event');
buildRoute('v2_get_plaid_accounts_path','/api/v2/users/:id/plaid_accounts');
buildRoute('v2_connect_plaid_account_path','/api/v2/users/:id/plaid_account');
buildRoute('v2_select_plaid_business_sub_accounts_path','/api/v2/users/:id/select_plaid_business_sub_accounts');
buildRoute('v2_deselect_plaid_business_sub_account_path','/api/v2/users/:id/deselect_plaid_business_sub_account/:bank_sub_account_id');
buildRoute('v2_get_amendment_contract_template_path','/api/v2/users/:id/amendment_contract_template');
buildRoute('v2_get_user_payment_methods_path','/api/v2/users/:id/payment_methods');
buildRoute('v2_onboarding_sequence_path','/api/v2/users/:id/onboarding_sequence');
buildRoute('v2_complete_onboarding_step_path','/api/v2/users/:id/onboarding_sequence/:step');
buildRoute('v2_update_onboarding_data_path','/api/v2/users/:id/onboarding_data');
buildRoute('v2_get_onboarding_flow_templates_path','/api/v2/users/:id/onboarding_sequence/flow_templates');
buildRoute('v2_get_onboarding_send_test_templates_path','/api/v2/users/:id/onboarding_sequence/send_test_templates');
buildRoute('v2_get_onboarding_default_project_path','/api/v2/users/:id/onboarding_sequence/default_workspace');
buildRoute('v2_update_onboarding_flow_template_path','/api/v2/users/:id/onboarding_sequence/update_onboarding_flow');
buildRoute('v2_import_onboarding_flow_template_path','/api/v2/users/:id/onboarding_sequence/import_onboarding_flow_template');
buildRoute('v2_send_test_flow_from_setup_guide_path','/api/v2/users/:id/onboarding_sequence/send_test_flow_from_setup_guide');
buildRoute('v2_create_smaf_with_services_path','/api/v2/users/:id/onboarding/create_smaf_with_services');
buildRoute('v2_create_onboarding_smaf_path','/api/v2/users/:id/onboarding/create_onboarding_smaf');
buildRoute('v2_create_onboarding_puf_path','/api/v2/users/:id/onboarding/create_onboarding_puf');
buildRoute('v2_create_onboarding_invoice_template_path','/api/v2/onboarding/invoice_template');
buildRoute('v2_create_onboarding_proposal_template_path','/api/v2/onboarding/proposal_template');
buildRoute('v2_scheduler_onboarding_session_config_path','/api/v2/users/scheduler_onboarding/session_config');
buildRoute('v2_scheduler_onboarding_update_session_config_path','/api/v2/users/scheduler_onboarding/session_config');
buildRoute('v2_set_mobile_session_data_path','/api/v2/users/:id/set_mobile_session_data');
buildRoute('v2_update_activity_notification_status_path','/api/v2/users/:id/update_activity_notification_status');
buildRoute('v2_set_mark_activity_notification_as_seen_path','/api/v2/users/:id/mark_activity_notification_as_seen');
buildRoute('v2_mark_all_web_notifications_as_seen_path','/api/v2/users/:id/mark_all_web_notifications_as_seen');
buildRoute('v2_user_get_recent_web_notifications_path','/api/v2/users/:id/web_notifications');
buildRoute('v2_user_get_ae_calendly_link_path','/api/v2/users/:id/ae_calendly_link');
buildRoute('v2_get_track_tool_url_path','/api/v2/users/:id/track_tool_url');
buildRoute('v2_users_send_password_link_path','/api/v2/users/:id/send_password_link');
buildRoute('send_set_password_link_path','/api/v2/users/send_set_password_link');
buildRoute('v2_users_reset_link_path','/api/v2/users/reset_link');
buildRoute('v2_users_send_magic_link_path','/api/v2/users/loginless_client_portal_link');
buildRoute('v2_validate_two_factor_auth_path','/api/v2/users/validate-login-2fa');
buildRoute('v2_send_two_factor_code_path','/api/v2/users/send-login-2fa');
buildRoute('v2_update_login_2fa_state_path','/api/v2/users/update-login-2fa-state');
buildRoute('v2_save_phone_number_and_start_2fa_path','/api/v2/users/save_phone_number_and_start_2fa');
buildRoute('v2_ab_test_variant_path','/api/v2/users/abt_variant/:name');
buildRoute('v2_add_specific_ab_test_variant_path','/api/v2/users/abt_variant');
buildRoute('v2_update_calendar_color_path','/api/v2/users/:id/change_calendar_color');
buildRoute('v2_users_reset_password_path','/api/v2/users/reset_password');
buildRoute('v2_user_disconnect_social_account_path','/api/v2/users/:id/disconnect_social_account');
buildRoute('v2_user_update_phone_number_for_two_factor_authentication_path','/api/v2/users/:id/2fa_phone_number');
buildRoute('v2_user_update_user_email_path','/api/v2/users/:id/update_user_email');
buildRoute('v2_user_connect_social_account_path','/api/v2/users/:id/connect_social_account');
buildRoute('v2_out_of_office_config_path','/api/v2/users/:id/out_of_office_config');
buildRoute('v2_edit_out_of_office_config_path','/api/v2/users/:id/out_of_office_config');
buildRoute('v2_m_end_out_of_office_path','/api/v2/m/users/:id/end_out_of_office');
buildRoute('v2_end_out_of_office_path','/api/v2/users/:id/end_out_of_office');
buildRoute('v2_users_change_password_path','/api/v2/users/:id/password');
buildRoute('v2_users_unauthenticated_change_password_path','/api/v2/users/password_by_email');
buildRoute('v2_update_user_path','/api/v2/users/:id');
buildRoute('v2_users_convert_user_to_vendor_path','/api/v2/user/:id/companies');
buildRoute('v2_set_user_temporary_password_path','/api/v2/users/:user_id/temp_password');
buildRoute('v2_malkut_set_client_as_vendor_path','/api/v2/users/:user_id/set_client_as_vendor');
buildRoute('v2_malkut_set_vendor_as_client_path','/api/v2/users/:user_id/set_vendor_as_client');
buildRoute('v2_malkut_unsubscribe_path','/api/v2/users/:user_id/unsubscribe');
buildRoute('v2_email_unsubscribe_path','/api/v2/users/unsubscribe_from_email');
buildRoute('v2_email_unsubscribe_post_path','/api/v2/users/unsubscribe_from_email/:a');
buildRoute('v2_toggle_unsubscribe_user_from_email_path','/api/v2/users/toggle_unsubscribe_user_from_email/:user_id');
buildRoute('v2_get_email_unsubscribe_path','/api/v2/users/unsubscribe_from_email/:company_id');
buildRoute('v2_malkut_refresh_gmail_profile_path','/api/v2/users/:user_id/refresh_gmail_profile');
buildRoute('v2_save_default_signature_path','/api/v2/users/:user_id/signature');
buildRoute('v2_user_accept_terms_of_service_path','/api/v2/users/:user_id/accept_terms_of_service');
buildRoute('v2_get_user_tos_to_accept_path','/api/v2/users/:user_id/unaccepted_tos');
buildRoute('v2_update_default_auto_expiration_path','/api/v2/users/:user_id/default_auto_expiration');
buildRoute('v2_save_bookkeeper_user_path','/api/v2/bookkeeper/:id/invite');
buildRoute('v2_save_ui_persistence_path','/api/v2/users/:id/ui_persistence');
buildRoute('v2_refetch_ui_persistence_path','/api/v2/users/:id/ui_persistence');
buildRoute('v2_get_tags_path','/api/v2/tags');
buildRoute('v2_get_tag_usage_path','/api/v2/tags/:tag_id');
buildRoute('v2_create_tag_path','/api/v2/tags');
buildRoute('v2_connect_tag_path','/api/v2/tags/connect_tag');
buildRoute('v2_disconnect_tag_path','/api/v2/tags/disconnect_tag');
buildRoute('v2_delete_tag_path','/api/v2/tags/:tag_id');
buildRoute('v2_update_tag_path','/api/v2/tags/:tag_id');
buildRoute('v2_users_account_transactions_path','/api/v2/reporting/users/:user_id/reports/transactions');
buildRoute('v2_user_bookings_path','/api/v2/reporting/users/:user_id/reports/bookings');
buildRoute('v2_user_reports_calendar_path','/api/v2/reporting/users/:user_id/reports/calendar');
buildRoute('v2_user_reports_analytics_path','/api/v2/reporting/users/:user_id/reports/analytics');
buildRoute('v2_user_reports_sales_path','/api/v2/reporting/users/:user_id/reports/sales');
buildRoute('v2_download_user_reports_sales_path','/api/v2/reporting/users/:user_id/reports/csv/sales');
buildRoute('v2_get_report_by_date_path','/api/v2/reporting/users/:user_id/reports/totals');
buildRoute('v2_get_leads_report_by_date_path','/api/v2/reporting/users/:user_id/reports/totals/leads');
buildRoute('v2_get_new_leads_reports_by_date_path','/api/v2/reporting/users/:user_id/reports/leads');
buildRoute('v2_download_leads_report_by_date_path','/api/v2/reporting/users/:user_id/reports/csv/leads');
buildRoute('v2_user_profit_loss_path','/api/v2/reporting/users/:user_id/reports/profit_loss');
buildRoute('v2_user_reports_payments_path','/api/v2/reporting/users/:user_id/reports/payments');
buildRoute('v2_get_company_expenses_path','/api/v2/companies/:id/expenses');
buildRoute('v2_get_company_expenses_export_path','/api/v2/companies/:id/expenses/export');
buildRoute('v2_company_bookings_path','/api/v2/reporting/companies/:company_id/reports/bookings');
buildRoute('v2_get_company_sent_invoices_path','/api/v2/companies/:id/sent_invoices');
buildRoute('v2_get_company_draft_invoices_path','/api/v2/companies/:id/draft_invoices');
buildRoute('v2_get_company_sent_invoices_count_path','/api/v2/companies/:id/sent_invoices_count');
buildRoute('v2_delete_archive_reason_path','/api/v2/companies/:id/delete_archive_reason');
buildRoute('v2_user_reports_payments_obsolete_route_path','/api/v2/users/:user_id/reports/payments');
buildRoute('v2_user_reports_calendar_obsolete_route_path','/api/v2/users/:user_id/reports/calendar');
buildRoute('v2_get_files_hub_table_report_path','/api/v2/files_hub/table_report');
buildRoute('v2_get_company_sent_contracts_path','/api/v2/files_hub/contracts/sent');
buildRoute('v2_get_company_draft_contracts_path','/api/v2/files_hub/contracts/draft');
buildRoute('v2_get_company_sent_files_path','/api/v2/files_hub/sent');
buildRoute('v2_get_company_draft_files_path','/api/v2/files_hub/draft');
buildRoute('v2_get_sent_invoices_path','/api/v2/files_hub/invoices/sent');
buildRoute('v2_get_draft_invoices_path','/api/v2/files_hub/invoices/draft');
buildRoute('v2_get_sent_invoices_count_path','/api/v2/files_hub/invoices/sent/count');
buildRoute('v2_get_atws_data_path','/api/v2/users/:user_id/atws');
buildRoute('v2_get_activated_state_path','/api/v2/users/:user_id/activated');
buildRoute('v2_get_mobile_active_state_path','/api/v2/users/mobile_active');
buildRoute('v2_users_user_by_email_path','/api/v2/users/by_email');
buildRoute('v2_users_get_user_data_path','/api/v2/users/get_user_min_data');
buildRoute('v2_users_user_by_reset_guid_path','/api/v2/users/by_reset_guid');
buildRoute('v2_get_calendar_provider_path','/api/v2/users/:id/calendar_provider');
buildRoute('v2_delete_calendar_provider_path','/api/v2/users/:id/calendar_provider');
buildRoute('v2_update_calendar_provider_path','/api/v2/users/:id/calendar_provider');
buildRoute('v2_get_integrated_calendars_list_path','/api/v2/users/:id/calendar_provider/calendar_importer/origin_calendars_list');
buildRoute('v2_get_integrated_calendars_imported_events_path','/api/v2/users/:id/calendar_provider/calendar_importer/events/:from_time/:to_time');
buildRoute('v2_set_google_calendar_importer_path','/api/v2/users/:id/calendar_provider/calendar_importer');
buildRoute('v2_set_import_path','/api/v2/users/:id/calendar_provider/calendar_importer/calendar_import/:import_id');
buildRoute('v2_update_notification_settings_path','/api/v2/users/:id/notification_settings');
buildRoute('v2_get_notification_settings_path','/api/v2/users/:id/notification_settings');
buildRoute('v2_update_push_notification_settings_path','/api/v2/users/:id/push_notification_settings');
buildRoute('v2_get_push_notification_settings_path','/api/v2/users/:id/push_notification_settings');
buildRoute('v2_google_auth_url_path','/api/v2/users/:id/google_auth_url');
buildRoute('v2_set_google_auth_code_path','/api/v2/users/:id/set_google_auth_code');
buildRoute('v2_get_zoom_auth_provider_path','/api/v2/users/:id/zoom_auth_provider');
buildRoute('v2_zoom_auth_url_path','/api/v2/users/:id/zoom_auth_url');
buildRoute('v2_set_zoom_auth_code_path','/api/v2/users/:id/set_zoom_auth_code');
buildRoute('zoom_notifications_path','/api/v2/zoom_notifications');
buildRoute('v2_create_video_conference_path','/api/v2/users/:id/video_conference');
buildRoute('v2_cancel_video_meeting_path','/api/v2/users/:id/video_conference');
buildRoute('v2_disconnect_zoom_path','/api/v2/users/:id/zoom');
buildRoute('get_nylas_notifications_path','/api/v2/nylas_notifications');
buildRoute('post_nylas_notifications_path','/api/v2/nylas_notifications');
buildRoute('v2_nylas_auth_url_path','/api/v2/users/:id/nylas_auth_url');
buildRoute('v2_set_nylas_auth_code_path','/api/v2/users/:id/set_nylas_auth_code');
buildRoute('v2_get_email_provider_path','/api/v2/nylas_email_provider');
buildRoute('v2_set_nylas_calendar_importer_path','/api/v2/users/:id/calendar_provider/set_calendar_importer');
buildRoute('v2_get_calendars_list_path','/api/v2/users/:id/calendars_list');
buildRoute('v2_get_email_providers_path','/api/v2/users/:id/email_providers');
buildRoute('v2_set_active_email_provider_path','/api/v2/users/:id/email_provider_type');
buildRoute('v2_allow_ai_leads_path','/api/v2/users/:id/email_provider/allow_ai_leads');
buildRoute('v2_delete_email_provider_path','/api/v2/users/:id/email_providers/:provider_type');
buildRoute('v2_get_calendar_items_path','/api/v2/users/:id/calendar_items');
buildRoute('v2_get_calendar_item_path','/api/v2/users/:id/calendar_items/:calendar_item_id');
buildRoute('v2_create_calendar_item_path','/api/v2/users/:id/calendar_item');
buildRoute('v2_delete_calendar_item_path','/api/v2/users/:id/calendar_items/:calendar_item_id');
buildRoute('v2_update_calendar_item_path','/api/v2/users/:id/calendar_items/:calendar_item_id');
buildRoute('v2_resync_calendar_item_path','/api/v2/users/:id/calendar_items/:calendar_item_id/resync');
buildRoute('v2_get_calendar_events_on_date_path','/api/v2/users/:id/calendar_events');
buildRoute('v2_google_contacts_path','/api/v2/users/:id/google_contacts');
buildRoute('v2_google_integrations_path','/api/v2/users/:id/google_integrations');
buildRoute('v2_delete_bank_account_path','/api/v2/users/:user_id/bank_account/:id');
buildRoute('v2_delete_stripe_card_path','/api/v2/users/:user_id/stripe_card/:id');
buildRoute('v2_remove_user_device_path','/api/v2/users/:id/devices/:token');
buildRoute('v2_delete_default_auto_expiration_path','/api/v2/users/:user_id/default_auto_expiration');
buildRoute('v2_user_save_identificaion_url_path','/api/v2/users/:id/save_identification/:type');
buildRoute('v2_user_identification_image_status_path','/api/v2/users/:id/identification_status');
buildRoute('v2_user_get_zapier_api_key_path','/api/v2/users/:id/integrations/zapier');
buildRoute('v2_user_request_new_integration_path','/api/v2/users/:id/request_new_integration');
buildRoute('v2_user_check_full_ssn_required_path','/api/v2/users/:id/check_full_id_required');
buildRoute('v2_get_last_sent_pvl_path','/api/v2/users/:user_id/last_sent_pvl');
buildRoute('v2_user_referral_info_path','/api/v2/users/:id/user_referral_info');
buildRoute('v2_user_tags_path','/api/v2/users/:id/user_tags');
buildRoute('v2_finance_app_webhook_path','/api/v2/finance/webhook');
buildRoute('v2_finance_app_token_path','/api/v2/finance/token');
buildRoute('v2_finance_authenticate_token_path','/api/v2/finance/auth');
buildRoute('v2_finance_app_push_notification_path','/api/v2/finance/push_notification');
buildRoute('v2_finance_app_bank_info_path','/api/v2/finance/bank_info/:id');
buildRoute('v2_finance_app_project_details_path','/api/v2/finance/project/:id');
buildRoute('v2_finance_app_project_details_with_session_path','/api/v2/finance/project_with_session/:id');
buildRoute('v2_finance_app_get_projects_path','/api/v2/finance/projects');
buildRoute('v2_finance_app_subscription_plan_path','/api/v2/finance/subscription_plan/:account_id');
buildRoute('v2_fred_health_check_path','/api/v2/fred/health_check');
buildRoute('v2_fred_webhook_path','/api/v2/fred/webhook');
buildRoute('v2_fred_terminal_connection_path','/api/v2/terminal/connection');
buildRoute('v2_fred_terminal_location_path','/api/v2/terminal/location');
buildRoute('v2_company_pay_links_path','/api/v2/companies/:company_id/pay_links');
buildRoute('v2_client_pay_links_path','/api/v2/companies/:company_id/clients/:client_id/pay_links');
buildRoute('v2_pay_link_path','/api/v2/companies/:company_id/pay_links/:_id');
buildRoute('v2_get_campaigns_path','/api/v2/campaigns');
buildRoute('v2_get_whats_new_live_announcements_path','/api/v2/whats_new_announcements');
buildRoute('v2_get_tax_entities_path','/api/v2/users/tax_entities');
buildRoute('v2_create_tax_mailing_path','/api/v2/users/taxes/:year/mail');
buildRoute('v2_get_tax_documents_delivery_consent_path','/api/v2/users/taxes/documents/delivery_consent');
buildRoute('v2_save_tax_documents_delivery_consent_path','/api/v2/users/taxes/documents/delivery_consent');
buildRoute('v2_select_filing_method_path','/api/v2/users/tax_entities/filing_method/:filing_method');
buildRoute('v2_list_tax_documents_path','/api/v2/users/taxes/documents');
buildRoute('v2_company_filing_details_path','/api/v2/users/taxes/:company_id/details');
buildRoute('v2_create_tin_verification_path','/api/v2/users/taxes/:company_id/tin_verification');
buildRoute('v2_get_company_current_tin_verification_status_path','/api/v2/users/taxes/:company_id/tin_verification/status');
buildRoute('v2_get_user_current_verification_statuses_path','/api/v2/users/taxes/tin_verifications');
buildRoute('v2_tax_status_update_notification_path','/api/v2/tax/status_update_notification');
buildRoute('v2_refer_users_by_email_path','/api/v2/referrals/refer_users_by_email');
buildRoute('v2_user_referrals_path','/api/v2/referrals/user_referrals');
buildRoute('v2_referrals_by_ids_path','/api/v2/referrals/referrals_by_ids');
buildRoute('v2_user_referral_stats_path','/api/v2/referrals/user_referral_stats');
buildRoute('v2_campaign_share_link_path','/api/v2/referrals/campaign_share_link');
buildRoute('v2_pending_commission_referrals_path','/api/v2/referrals/pending_commission_referrals');
buildRoute('v2_pending_commission_payout_referrals_path','/api/v2/referrals/pending_commission_payout_referrals');
buildRoute('v2_campaign_details_path','/api/v2/referrals/campaign_details');
buildRoute('v2_send_referral_reminder_path','/api/v2/referrals/send_referral_reminder');
buildRoute('v2_referral_suggestions_path','/api/v2/referrals/referral_suggestions');
buildRoute('v2_fetch_referral_suggestions_path','/api/v2/referrals/fetch_referral_suggestions');
buildRoute('v2_create_referral_path','/api/v2/referrals/create_referral');
buildRoute('v2_update_personal_invite_message_path','/api/v2/referrals/personal_invite_message');
buildRoute('v2_get_referral_code_path','/api/v2/referrals/referral_code/:code');
buildRoute('v2_save_referral_path','/api/v2/referrals/save_referral');
buildRoute('v2_update_campaign_path','/api/v2/referrals/campaign');
buildRoute('v2_update_coupon_code_path','/api/v2/referrals/coupon_code');
buildRoute('v2_get_self_onboarding_step_path','/api/v2/users/:id/self_onboarding/steps');
buildRoute('v2_set_self_onboarding_step_complete_path','/api/v2/users/:id/self_onboarding/steps/:step_name');
buildRoute('v2_self_onboarding_abort_path','/api/v2/users/:id/self_onboarding/abort');
buildRoute('v2_companies_save_image_path','/api/v2/companies/:id/photo/:type');
buildRoute('v2_user_save_image_path','/api/v2/users/:id/photo/:type');
buildRoute('v2_company_save_asset_path','/api/v2/companies/:id/assets/:type');
buildRoute('v2_company_save_batch_files_path','/api/v2/companies/:id/assets');
buildRoute('v2_user_workspace_files_path','/api/v2/users/:id/workspace_files');
buildRoute('v2_get_current_user_path','/api/v2/users/:id');
buildRoute('v2_get_kyc_info_path','/api/v2/users/:id/kyc-info');
buildRoute('v2_get_recent_sent_files_for_user_path','/api/v2/users/:id/recent_sent_files');
buildRoute('v2_workspace_file_path','/api/v2/workspace_files/:id');
buildRoute('v2_workspace_file_members_path','/api/v2/workspace_files/:id/members');
buildRoute('v2_workspace_file_version_path','/api/v2/workspace_files/:id/versions/:version');
buildRoute('v2_workspace_files_print_path','/api/v2/workspace_files/:id/print/:parts_to_print');
buildRoute('v2_workspace_files_print_payment_path','/api/v2/workspace_files/:id/payments/:payment_id/print');
buildRoute('v2_workspace_file_status_path','/api/v2/workspace_files/:id/status');
buildRoute('v2_get_form_component_presets_path','/api/v2/workspace_files/form_component/presets');
buildRoute('v2_update_workspace_file_path','/api/v2/workspace_files/:id');
buildRoute('v2_workspace_file_update_status_path','/api/v2/workspace_files/:id/status');
buildRoute('v2_deactivate_workspace_file_path','/api/v2/workspace_files/:id/block');
buildRoute('v2_activate_workspace_file_path','/api/v2/workspace_files/:id/unblock');
buildRoute('v2_update_workspace_file_image_path','/api/v2/workspace_files/:id/image');
buildRoute('v2_update_workspace_file_header_icon_path','/api/v2/workspace_files/:id/icon');
buildRoute('v2_update_workspace_file_header_bill_to_path','/api/v2/workspace_files/:id/bill_to');
buildRoute('v2_workspace_file_accept_new_version_path','/api/v2/workspace_files/:id/accept');
buildRoute('v2_update_workspace_file_auto_expiration_path','/api/v2/workspace_files/:id/auto_expiration');
buildRoute('v2_workspace_file_update_allow_gratuity_path','/api/v2/workspace_files/:id/allow_gratuity');
buildRoute('v2_sync_workspace_file_with_quickbooks_path','/api/v2/workspace_files/:id/sync_with_qb');
buildRoute('v2_unsync_payment_from_quickbooks_path','/api/v2/workspace_files/:id/unsync_from_qb');
buildRoute('v2_workspace_send_email_path','/api/v2/workspace_files/:id/emails');
buildRoute('v2_workspace_send_file_preview_to_myself_path','/api/v2/workspace_files/:id/email_preview');
buildRoute('v2_workspace_send_file_preview_path','/api/v2/workspace_files/:id/file_preview');
buildRoute('v2_workspace_save_item_suggestions_path','/api/v2/workspace_files/:id/item_suggestions');
buildRoute('v2_workspace_send_payment_path','/api/v2/workspace_files/:id/pay');
buildRoute('v2_create_workspace_file_draft_path','/api/v2/workspace_files/:id/draft');
buildRoute('v2_delete_workspace_file_path','/api/v2/workspace_files/:id');
buildRoute('v2_delete_workspace_file_draft_path','/api/v2/workspace_files/:id/draft');
buildRoute('v2_delete_workspace_file_auto_expiration_path','/api/v2/workspace_files/:id/auto_expiration');
buildRoute('v2_delete_workspace_file_header_icon_path','/api/v2/workspace_files/:id/icon');
buildRoute('v2_delete_workspace_file_header_bill_to_path','/api/v2/workspace_files/:id/bill_to');
buildRoute('v2_delete_activity_item_path','/api/v2/workspaces/:id/activityItem/:feed_item_id');
buildRoute('v2_workspace_file_get_agreement_template_path','/api/v2/workspace_files/:id/templates/agreement/');
buildRoute('v2_workspace_file_select_agreement_template_path','/api/v2/workspace_files/:id/templates/agreement/:agreement_template_id');
buildRoute('v2_workspace_file_save_agreement_path','/api/v2/workspace_files/:id/agreement');
buildRoute('v2_workspace_file_update_agreement_with_file_path','/api/v2/workspace_files/:id/agreement/update_with_file');
buildRoute('v2_workspace_file_get_payments_template_path','/api/v2/workspace_files/:id/templates/payments/');
buildRoute('v2_workspace_file_get_payment_path','/api/v2/workspace_files/:id/templates/payments/:invoice_id/');
buildRoute('v2_workspace_file_add_payment_path','/api/v2/workspace_files/:id/payments');
buildRoute('v2_workspace_file_send_payment_reminder_path','/api/v2/workspace_files/:id/payments/:payment_id/reminders');
buildRoute('v2_workspace_file_request_milestone_payment_path','/api/v2/workspace_files/:id/payments/:payment_id/request_milestone_payment');
buildRoute('v2_workspace_file_payment_pay_path','/api/v2/workspace_files/:id/payments/:payment_id/payment_methods/:payment_method_id');
buildRoute('v2_workspace_file_pre_charge_validate_path','/api/v2/workspace_files/:id/payments/:payment_id/payment_methods/:payment_method_id/validate');
buildRoute('v2_workspace_file_update_payment_path','/api/v2/workspace_files/:id/payments/:payment_id');
buildRoute('v2_workspace_file_select_payments_template_path','/api/v2/workspace_files/:id/templates/payments/:payments_template_id');
buildRoute('v2_workspace_file_update_auto_payment_path','/api/v2/workspace_files/:id/update_auto_payment');
buildRoute('v2_workspace_file_update_accepted_payment_method_path','/api/v2/workspace_files/:id/accepted_payment_method');
buildRoute('v2_workspace_file_delete_payment_path','/api/v2/workspace_files/:id/payments/:payment_id');
buildRoute('v2_workspace_file_mark_as_refunded_path','/api/v2/workspace_files/:id/payments/:payment_id/refund');
buildRoute('v2_workspace_file_toggle_payment_as_instant_path','/api/v2/workspace_files/:id/payments/:payment_id/instant_payout');
buildRoute('v2_workspace_file_allow_charged_instant_payout_path','/api/v2/workspace_files/:id/payments/:payment_id/allow_charged_instant_payout');
buildRoute('v2_workspace_file_update_payment_schedule_type_path','/api/v2/workspace_files/:id/toggle_recurring_payment');
buildRoute('v2_workspace_file_update_recurring_payment_path','/api/v2/workspace_files/:id/update_recurring_payment');
buildRoute('v2_workspace_file_cancel_recurring_payment_path','/api/v2/workspace_files/:id/cancel_recurring_payment');
buildRoute('v2_workspace_file_update_recurring_payment_method_path','/api/v2/workspace_files/:id/payment_method');
buildRoute('v2_workspace_file_get_proposal_template_path','/api/v2/workspace_files/:id/templates/proposal/');
buildRoute('v2_workspace_file_add_service_path','/api/v2/workspace_files/:id/proposal/services');
buildRoute('v2_workspace_file_add_package_path','/api/v2/workspace_files/:id/proposal/packages/:package_id');
buildRoute('v2_workspace_file_update_service_on_proposal_path','/api/v2/workspace_files/:id/proposal/services/:service_id');
buildRoute('v2_workspace_file_update_proposal_path','/api/v2/workspace_files/:id/proposal');
buildRoute('v2_workspace_file_apply_voucher_path','/api/v2/workspace_files/:id/proposal/voucher/:code');
buildRoute('v2_workspace_file_remove_voucher_path','/api/v2/workspace_files/:id/proposal/voucher');
buildRoute('v2_workspace_file_select_proposal_template_path','/api/v2/workspace_files/:id/templates/proposal/:proposal_template_id');
buildRoute('v2_workspace_file_delete_service_on_proposal_path','/api/v2/workspace_files/:id/proposal/services/:service_id');
buildRoute('v2_workspace_file_calculate_tax_path','/api/v2/workspace_files/:id/proposal/tax');
buildRoute('v2_workspace_file_order_packages_and_services_path','/api/v2/workspace_files/:id/proposal/order');
buildRoute('v2_workspace_file_update_brochure_proposal_image_path','/api/v2/workspace_files/:id/brochure/brochure_proposals/:brochure_proposal_id/image');
buildRoute('v2_workspace_file_select_brochure_proposal_path','/api/v2/workspace_files/:id/brochure/brochure_proposals/:brochure_proposal_id/select');
buildRoute('v2_workspace_file_submit_brochure_path','/api/v2/workspace_files/:id/brochure/submit');
buildRoute('v2_workspace_file_set_invoice_by_template_path','/api/v2/workspace_files/:id/templates/invoice/:invoice_template_id');
buildRoute('v2_workspace_file_set_proposal_file_by_template_path','/api/v2/workspace_files/:id/templates/proposal_file/:proposal_template_id');
buildRoute('v2_workspace_file_toggle_tax_path','/api/v2/workspace_files/:id/proposal/tax');
buildRoute('v2_workspace_file_toggle_svc_path','/api/v2/workspace_files/:id/proposal/svc');
buildRoute('v2_workspace_file_update_packages_order_path','/api/v2/workspace_files/:id/proposal/packages/order');
buildRoute('v2_workspace_file_update_service_on_package_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_update_services_order_on_package_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services');
buildRoute('v2_workspace_file_update_package_on_proposal_path','/api/v2/workspace_files/:id/proposal/packages/:package_id');
buildRoute('v2_workspace_file_update_service_image_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services/:service_id/image');
buildRoute('v2_workspace_file_update_standalone_service_image_path','/api/v2/workspace_files/:id/proposal/services/:service_id/image');
buildRoute('v2_workspace_file_package_additional_service_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services');
buildRoute('v2_workspace_file_delete_service_on_package_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_workspace_file_delete_package_on_proposal_path','/api/v2/workspace_files/:id/package/:package_id');
buildRoute('v2_workspace_file_delete_service_image_path','/api/v2/workspace_files/:id/proposal/packages/:package_id/services/:service_id/image');
buildRoute('v2_workspace_file_delete_standalone_service_image_path','/api/v2/workspace_files/:id/proposal//services/:service_id/image');
buildRoute('v2_workspace_file_move_service_path','/api/v2/workspace_files/:id/proposal/services/:service_id/move');
buildRoute('v2_workspace_file_get_questionnaire_template_path','/api/v2/workspace_files/:id/templates/questionnaire/');
buildRoute('v2_workspace_file_select_questionnaire_template_path','/api/v2/workspace_files/:id/templates/questionnaire/:questionnaire_template_id');
buildRoute('v2_workspace_file_submit_question_path','/api/v2/workspace_files/:id/questionnaire');
buildRoute('v2_workspace_file_create_question_path','/api/v2/workspace_files/:id/questionnaire/questions');
buildRoute('v2_workspace_file_create_event_questions_path','/api/v2/workspace_files/:id/questionnaire/event/questions');
buildRoute('v2_workspace_file_update_question_path','/api/v2/workspace_files/:id/questionnaire/questions/:question_id');
buildRoute('v2_workspace_file_delete_question_path','/api/v2/workspace_files/:id/questionnaire/questions/:question_id');
buildRoute('v2_workspace_file_update_questions_order_path','/api/v2/workspace_files/:id/questionnaire/update_questions_order');
buildRoute('v2_workspace_file_create_brochure_proposal_path','/api/v2/workspace_files/:id/brochure/brochure_proposal');
buildRoute('v2_workspace_file_delete_brochure_proposal_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id');
buildRoute('v2_workspace_file_create_brochure_a_la_carte_proposal_path','/api/v2/workspace_files/:id/brochure/a_la_carte');
buildRoute('v2_workspace_file_update_brochure_a_la_carte_proposal_path','/api/v2/workspace_files/:id/brochure/a_la_carte');
buildRoute('v2_workspace_file_delete_brochure_a_la_carte_proposal_path','/api/v2/workspace_files/:id/brochure/a_la_carte');
buildRoute('v2_add_service_to_brochure_a_la_carte_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services');
buildRoute('v2_update_service_on_brochure_a_la_carte_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services/:service_id');
buildRoute('v2_delete_service_on_brochure_a_la_carte_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services/:service_id');
buildRoute('v2_workspace_file_update_brochure_proposal_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id');
buildRoute('v2_workspace_file_update_brochure_proposals_order_path','/api/v2/workspace_files/:id/brochure/brochure_proposals/order');
buildRoute('v2_add_package_to_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/packages/:package_id');
buildRoute('v2_add_package_additional_service_to_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services');
buildRoute('v2_add_service_to_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/services');
buildRoute('v2_delete_service_from_brochure_proposal_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/services/:service_id');
buildRoute('v2_delete_service_on_package_from_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_delete_package_on_proposal_of_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/packages/:package_id');
buildRoute('v2_delete_service_image_on_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/packages/:package_id/services/:service_id/image');
buildRoute('v2_brochure_a_la_carte_delete_service_image_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services/:service_id/image');
buildRoute('v2_update_service_on_proposal_of_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/services/:service_id');
buildRoute('v2_update_package_on_proposal_of_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id');
buildRoute('v2_update_services_order_on_brochure_package_path','/api/v2/workspace_files/:id/brochure/brochure_proposals/:brochure_proposal_id/packages/:package_id/services');
buildRoute('v2_update_services_order_in_brochure_a_la_carte_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services');
buildRoute('v2_update_brochure_a_la_carte_service_selection_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services/:service_id/selection');
buildRoute('v2_update_service_on_package_of_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_update_service_image_on_brochure_path','/api/v2/workspace_files/:id/brochure/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id/image');
buildRoute('v2_brochure_a_la_carte_update_service_image_path','/api/v2/workspace_files/:id/brochure/a_la_carte/services/:service_id/image');
buildRoute('v2_create_file_brochure_greeting_path','/api/v2/workspace_files/:id/brochure/greeting');
buildRoute('v2_update_brochure_greeting_path','/api/v2/workspace_files/:id/brochure/greeting');
buildRoute('v2_delete_file_brochure_greeting_path','/api/v2/workspace_files/:id/brochure/greeting');
buildRoute('v2_update_brochure_greeting_image_path','/api/v2/workspace_files/:id/brochure/greeting/image');
buildRoute('v2_workspace_file_update_brochure_a_la_carte_image_path','/api/v2/workspace_files/:id/brochure/a_la_carte/image');
buildRoute('v2_workspace_file_get_brochure_template_path','/api/v2/workspace_files/:id/templates/brochure/');
buildRoute('v2_workspace_file_select_brochure_template_path','/api/v2/workspace_files/:id/templates/brochure/:brochure_template_id');
buildRoute('v2_workspace_file_update_timeline_section_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id');
buildRoute('v2_workspace_file_update_timeline_item_positions_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id/positions');
buildRoute('v2_workspace_file_update_timeline_item_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id/items/:timeline_item_id');
buildRoute('v2_workspace_file_create_timeline_section_path','/api/v2/workspace_files/:id/timeline/sections');
buildRoute('v2_workspace_file_create_timeline_item_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id/items');
buildRoute('v2_workspace_file_delete_timeline_section_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id');
buildRoute('v2_workspace_file_delete_timeline_item_path','/api/v2/workspace_files/:id/timeline/sections/:timeline_section_id/items/:timeline_item_id');
buildRoute('v2_workspace_file_get_timeline_template_path','/api/v2/workspace_files/:id/templates/timeline/');
buildRoute('v2_workspace_file_select_timeline_template_path','/api/v2/workspace_files/:id/templates/timeline/');
buildRoute('v2_save_timeline_template_path','/api/v2/companies/:id/templates/timeline');
buildRoute('v2_delete_timeline_template_path','/api/v2/companies/:id/templates/timeline/:timeline_template_id');
buildRoute('v2_get_people_path','/api/v2/people');
buildRoute('v2_get_user_contacts_path','/api/v2/user/:id/contacts');
buildRoute('v2_get_workspace_connections_for_contact_path','/api/v2/contacts/:contact_id/workspaces');
buildRoute('v2_get_contacts_count_path','/api/v2/contacts_count');
buildRoute('v2_get_created_users_path','/api/v2/user/created_users');
buildRoute('v2_has_preferred_vendors_path','/api/v2/has_preferred_vendors');
buildRoute('v2_get_preferred_vendors_path','/api/v2/preferred_vendors');
buildRoute('v2_get_suggested_preferred_vendors_path','/api/v2/suggested_preferred_vendors');
buildRoute('v2_get_contact_path','/api/v2/contacts/:contact_id');
buildRoute('v2_get_contact_payments_path','/api/v2/contacts/:contact_id/payments');
buildRoute('v2_get_contact_flows_path','/api/v2/contacts/:contact_id/flows');
buildRoute('v2_get_contact_feed_items_path','/api/v2/contacts/:contact_id/feed_items');
buildRoute('v2_get_contact_meetings_path','/api/v2/contacts/:contact_id/meetings');
buildRoute('v2_get_contact_all_meetings_path','/api/v2/contacts/:contact_id/all_meetings');
buildRoute('v2_get_contact_payments_summary_path','/api/v2/contacts/:contact_id/payments_summary');
buildRoute('v2_get_contacts_path','/api/v2/contacts');
buildRoute('v2_add_contact_enrichment_reaction_path','/api/v2/contacts/:contact_id/enrichment/reaction');
buildRoute('v2_update_contact_details_path','/api/v2/contacts/:contact_id/details');
buildRoute('v2_save_contact_path','/api/v2/contact');
buildRoute('v2_create_preferred_contact_path','/api/v2/create_preferred_contact');
buildRoute('v2_save_preferred_vendor_contact_path','/api/v2/preferred_vendor');
buildRoute('v2_update_preferred_vendor_contact_path','/api/v2/preferred_vendor');
buildRoute('v2_delete_contact_path','/api/v2/contact/:id');
buildRoute('v2_workspace_file_signature_path','/api/v2/workspace_files/:id/signature/:signature_id');
buildRoute('v2_workspace_file_signature_description_path','/api/v2/workspace_files/:id/signature/:signature_id/description');
buildRoute('v2_change_signature_signatory_path','/api/v2/workspace_files/:id/signature/:signature_id/change');
buildRoute('v2_workspace_file_agreement_add_signature_path','/api/v2/workspace_files/:id/agreement/signature');
buildRoute('v2_workspace_file_agreement_remove_signature_path','/api/v2/workspace_files/:id/agreement/signature/:signature_id');
buildRoute('v2_user_templates_get_email_signature_path','/api/v2/users/templates/email/signature/:email_signature_id');
buildRoute('v2_user_get_all_email_signatures_path','/api/v2/users/templates/email/signatures');
buildRoute('v2_user_templates_create_email_signature_path','/api/v2/users/templates/email/signature');
buildRoute('v2_user_templates_update_email_signature_path','/api/v2/users/templates/email/signature/:email_signature_id');
buildRoute('v2_user_templates_delete_email_signature_path','/api/v2/users/templates/email/signature/:email_signature_id');
buildRoute('v2_workspaces_path','/api/v2/workspaces');
buildRoute('v2_create_pipeline_view_path','/api/v2/workspaces/pipeline/views');
buildRoute('v2_delete_pipeline_view_path','/api/v2/workspaces/pipeline/views/:view_id');
buildRoute('v2_edit_pipeline_view_path','/api/v2/workspaces/pipeline/views/:view_id');
buildRoute('v2_workspaces_customize_pipeline_path','/api/v2/workspaces/customize_pipeline');
buildRoute('v2_workspaces_count_path','/api/v2/workspaces/counts');
buildRoute('v2_workspaces_has_any_path','/api/v2/workspaces/has_any');
buildRoute('v2_workspace_path','/api/v2/workspaces/:id');
buildRoute('v2_workspace_payments_path','/api/v2/workspaces/:id/payments');
buildRoute('v2_workspace_attachments_path','/api/v2/workspaces/:id/attachments');
buildRoute('v2_workspace_feed_path','/api/v2/workspaces/:id/feed');
buildRoute('v2_workspace_summary_path','/api/v2/workspaces/:id/summary');
buildRoute('v2_get_referral_email_path','/api/v2/workspaces/:id/referral_email');
buildRoute('v2_get_workspace_members_path','/api/v2/workspaces/:id/members');
buildRoute('v2_get_workspace_members_contacts_path','/api/v2/workspaces/:id/contacts');
buildRoute('v2_get_workflow_for_workspace_path','/api/v2/workspaces/:id/workflow');
buildRoute('v2_get_is_deletable_for_workspace_path','/api/v2/workspaces/:id/is_deletable');
buildRoute('v2_get_workspaces_all_meetings_path','/api/v2/workspaces/:id/all_meetings');
buildRoute('v2_get_eligible_mark_as_complete_flows_path','/api/v2/workspaces/:id/eligible_mark_as_complete_flows');
buildRoute('v2_update_workspace_tab_path','/api/v2/workspaces/:id/tab_name');
buildRoute('v2_update_workspace_stages_path','/api/v2/workspaces/:id/stages/:stage_id');
buildRoute('v2_update_pipeline_settings_path','/api/v2/workspaces/:id/pipeline');
buildRoute('v2_block_workspace_path','/api/v2/workspaces/:id/block');
buildRoute('v2_unblock_workspace_path','/api/v2/workspaces/:id/unblock');
buildRoute('v2_workspace_update_notification_path','/api/v2/workspaces/:id/notifications');
buildRoute('v2_workspace_update_team_members_path','/api/v2/workspaces/:id/team_members');
buildRoute('v2_workspace_dismiss_pvl_requests_path','/api/v2/workspaces/:id/preferred_vendors_list');
buildRoute('v2_mark_feed_items_as_seen_path','/api/v2/workspaces/:id/feed_items/seen');
buildRoute('v2_change_workflow_step_state_path','/api/v2/workspaces/:id/workflow_step/:step_or_task_id');
buildRoute('v2_update_email_template_path','/api/v2/workspaces/:id/workflow_step/:step_id/update');
buildRoute('v2_remove_sessions_with_unrelated_member_path','/api/v2/workspaces/:id/remove_sessions_with_unrelated_member');
buildRoute('v2_create_workspace_file_path','/api/v2/workspaces/:id/workspace_files');
buildRoute('v2_widget_create_inquiry_path','/api/v2/workspace/widget_inquiries');
buildRoute('v2_contact_form_inquiry_create_inquiry_path','/api/v2/workspace/inquiries');
buildRoute('v2_contact_form_sms_consent_mode_path','/api/v2/workspaces/:id/sms_consent_mode');
buildRoute('v2_vendor_create_inquiry_path','/api/v2/workspace/vendor_inquiries');
buildRoute('v2_pvl_create_inquiry_path','/api/v2/workspace/pvl_inquiries');
buildRoute('v2_workspace_add_member_path','/api/v2/workspaces/:id/members');
buildRoute('v2_workspace_add_new_clients_path','/api/v2/workspaces/:id/clients');
buildRoute('v2_workspace_add_existing_client_path','/api/v2/workspaces/:id/clients/:client_email');
buildRoute('v2_workspace_send_referral_to_vendor_path','/api/v2/workspaces/:id/user/:user_id/referrals');
buildRoute('v2_transfer_workspace_path','/api/v2/workspaces/:id/user/:user_id/transfer_workspace');
buildRoute('v2_send_workspace_email_path','/api/v2/workspaces/:id/emails');
buildRoute('v2_set_workflow_instance_path','/api/v2/workspaces/:id/workflows/:workflow_id');
buildRoute('v2_get_temp_workflow_instance_path','/api/v2/workspaces/:id/workflows/:workflow_id/instance');
buildRoute('v2_save_edited_workflow_instance_path','/api/v2/workspaces/:id/workflows/:workflow_id/set_instance');
buildRoute('v2_update_applied_workflow_path','/api/v2/workspaces/:id/workflows/:workflow_id/update');
buildRoute('v2_add_workspace_attachments_path','/api/v2/workspaces/:id/attachments');
buildRoute('v2_create_template_preview_file_path','/api/v2/workspace/create_template_preview_file/:template_id/:file_type');
buildRoute('v2_workspace_send_message_path','/api/v2/workspaces/:id/message');
buildRoute('v2_mark_all_user_messages_as_read_path','/api/v2/workspaces/mark_all_user_messages_as_read');
buildRoute('v2_referral_thank_you_path','/api/v2/referrals/:referral_id/thank_you');
buildRoute('v2_delete_workspace_path','/api/v2/workspaces/:id');
buildRoute('v2_workspace_remove_member_path','/api/v2/workspaces/:id/members/:member_id');
buildRoute('v2_unset_workflow_instance_path','/api/v2/workspaces/:id/workflows/:workflow_id');
buildRoute('v2_delete_workspace_attachment_path','/api/v2/workspaces/:id/attachments/:attachment_id');
buildRoute('v2_workspace_delete_feed_item_path','/api/v2/workspaces/:id/feed/:feed_item_id');
buildRoute('v2_restore_deleted_flow_path','/api/v2/flow/:flow_id/restore');
buildRoute('v2_get_has_referrals_path','/api/v2/has_referrals');
buildRoute('v2_list_session_configs_path','/api/v2/users/:id/session_configs');
buildRoute('v2_create_session_config_path','/api/v2/users/:id/session_configs');
buildRoute('v2_duplicate_session_config_path','/api/v2/session_configs/copy/:session_id');
buildRoute('v2_update_session_config_path','/api/v2/users/:id/session_configs/:session_config_id');
buildRoute('v2_delete_session_config_path','/api/v2/users/:id/session_configs/:session_config_id');
buildRoute('v2_get_all_calendar_events_path','/api/v2/scheduler_provider/events');
buildRoute('v2_get_session_configs_path','/api/v2/companies/:id/session_configs');
buildRoute('v2_get_flow_by_source_flow_path','/api/v2/companies/:id/flow_by_source_flow');
buildRoute('v2_get_session_path','/api/v2/session/:id');
buildRoute('v2_create_session_path','/api/v2/session');
buildRoute('v2_reschedule_session_path','/api/v2/session/:id');
buildRoute('v2_delete_session_path','/api/v2/session/:id');
buildRoute('v2_scheduler_contact_path','/api/v2/session/unavailable/contact');
buildRoute('v2_workspace_save_preferred_vendors_list_path','/api/v2/workspaces/:id/preferred_vendors_lists');
buildRoute('v2_workspace_update_preferred_vendors_list_path','/api/v2/workspaces/:id/preferred_vendors_lists');
buildRoute('v2_workspace_request_preferred_vendors_list_path','/api/v2/workspaces/:id/request_preferred_vendors_list');
buildRoute('v2_workspace_send_preferred_vendors_list_path','/api/v2/workspaces/:id/send_preferred_vendors_list');
buildRoute('v2_dismiss_feed_pvl_request_path','/api/v2/workspaces/:id/feed/dismiss_pvl_request');
buildRoute('v2_get_preferred_vendors_list_path','/api/v2/preferred_vendors_list/:id');
buildRoute('v2_events_summary_path','/api/v2/events');
buildRoute('v2_get_filtered_events_path','/api/v2/filtered_events');
buildRoute('v2_event_details_path','/api/v2/events/:id/details');
buildRoute('v2_get_conflicting_events_path','/api/v2/events/:id/conflicting_events');
buildRoute('v2_create_event_path','/api/v2/events');
buildRoute('v2_create_workspaces_on_event_path','/api/v2/events/:id/workspaces');
buildRoute('v2_create_project_with_file_path','/api/v2/events/file/:file_type');
buildRoute('v2_create_event_with_client_path','/api/v2/events/client');
buildRoute('v2_update_event_path','/api/v2/events/:id');
buildRoute('v2_create_company_project_notes_path','/api/v2/events/:id/company_notes');
buildRoute('v2_update_company_project_notes_path','/api/v2/events/:id/company_notes');
buildRoute('v2_delete_company_project_notes_path','/api/v2/events/:id/company_notes/:note_id');
buildRoute('v2_update_event_image_path','/api/v2/events/:id/image');
buildRoute('v2_event_conflict_pvl_action_path','/api/v2/events/:id/conflict_pvl_action');
buildRoute('v2_company_project_notes_path','/api/v2/notes/workspace/:workspace_id');
buildRoute('v2_create_workspace_company_note_path','/api/v2/notes/workspace/:workspace_id');
buildRoute('v2_update_workspace_note_path','/api/v2/notes/:note_id/workspace/:workspace_id');
buildRoute('v2_update_client_note_path','/api/v2/notes/:note_id/contact/:contact_id');
buildRoute('v2_delete_company_note_path','/api/v2/notes/:note_id');
buildRoute('v2_unlock_ai_note_path','/api/v2/notes/:note_id/workspace/:workspace_id/unlock_ai_note');
buildRoute('v2_user_company_note_visit_path','/api/v2/notes/:note_id/client_visit');
buildRoute('v2_client_notes_path','/api/v2/notes/contact/:contact_id');
buildRoute('v2_create_client_note_path','/api/v2/notes/contact/:contact_id');
buildRoute('v2_fetch_note_path','/api/v2/notes/:note_id');
buildRoute('v2_fetch_home_widget_notes_path','/api/v2/notes/home_widget/fetch');
buildRoute('v2_create_project_dates_path','/api/v2/events/:id/project_dates');
buildRoute('v2_update_project_dates_path','/api/v2/events/:id/project_dates');
buildRoute('v2_delete_project_dates_path','/api/v2/events/:id/project_dates/:date_id');
buildRoute('v2_event_add_space_path','/api/v2/events/:id/space/:space_id');
buildRoute('v2_event_remove_space_path','/api/v2/events/:id/space/:space_id');
buildRoute('v2_company_path','/api/v2/companies/:id');
buildRoute('v2_malkut_get_company_path','/api/v2/companies/:id/malkut_get_company');
buildRoute('v2_get_setup_path','/api/v2/companies/:id/setup');
buildRoute('v2_company_get_questionnaires_path','/api/v2/companies/:id/questionnaires');
buildRoute('v2_company_get_refund_payment_data_path','/api/v2/companies/:id/refund_payment_data');
buildRoute('v2_get_company_quickbooks_tax_code_path','/api/v2/companies/:id/quickbooks/tax_code');
buildRoute('v2_get_company_quickbooks_deposit_account_path','/api/v2/companies/:id/quickbooks/deposit_account');
buildRoute('v2_get_company_quickbooks_settings_path','/api/v2/companies/:id/quickbooks/settings');
buildRoute('v2_update_company_quickbooks_settings_path','/api/v2/companies/:id/quickbooks/settings');
buildRoute('v2_company_get_additional_info_path','/api/v2/companies/:id/additional_info');
buildRoute('v2_get_font_upload_presigned_url_path','/api/v2/companies/:id/font_upload_presigned_url');
buildRoute('v2_get_booking_reviews_path','/api/v2/companies/:id/booking_reviews');
buildRoute('v2_get_company_settings_path','/api/v2/companies/:id/settings');
buildRoute('v2_get_company_pending_invite_path','/api/v2/companies/:id/get_company_pending_invite');
buildRoute('v2_update_company_path','/api/v2/companies/:id');
buildRoute('v2_malkut_update_company_path','/api/v2/companies/:id/malkut_update_company');
buildRoute('v2_companies_save_default_tax_path','/api/v2/companies/:id/tax');
buildRoute('v2_companies_save_default_svc_path','/api/v2/companies/:id/svc');
buildRoute('v2_save_package_template_path','/api/v2/companies/:id/templates/package/:package_id');
buildRoute('v2_publish_widget_path','/api/v2/companies/:id/widget/publish');
buildRoute('v2_update_automation_path','/api/v2/companies/:id/automation');
buildRoute('v2_update_owner_notification_path','/api/v2/companies/:id/notification');
buildRoute('v2_update_team_member_path','/api/v2/companies/:id/team/:member_id');
buildRoute('v2_update_company_settings_path','/api/v2/companies/:id/settings');
buildRoute('v2_update_company_quickbooks_tax_code_path','/api/v2/companies/:id/quickbooks/tax_code');
buildRoute('v2_update_company_quickbooks_zero_tax_code_path','/api/v2/companies/:id/quickbooks/zero_tax_code');
buildRoute('v2_update_company_quickbooks_deposit_account_path','/api/v2/companies/:id/quickbooks/deposit_account');
buildRoute('v2_update_company_expense_path','/api/v2/companies/:id/expenses/:expense_id');
buildRoute('v2_company_update_additional_info_path','/api/v2/companies/:id/additional_info');
buildRoute('v2_company_validate_company_web_site_path','/api/v2/companies/:id/website_url_data');
buildRoute('v2_companies_update_all_inclusive_instant_payouts_path','/api/v2/companies/:id/instant_payouts');
buildRoute('v2_add_company_font_path','/api/v2/companies/:id/add_font');
buildRoute('v2_update_booking_rating_enabled_path','/api/v2/companies/:id/booking_rating_enabled');
buildRoute('v2_update_thumbnail_visible_to_client_path','/api/v2/companies/:id/thumbnail');
buildRoute('v2_company_start_instant_branding_web_crawl_path','/api/v2/companies/:id/start_instant_branding_web_crawl');
buildRoute('v2_link_suggested_assets_to_company_path','/api/v2/companies/:id/link_suggested_assets_to_company');
buildRoute('v2_save_as_vendor_proposal_template_path','/api/v2/companies/:id/templates/vendor_proposal');
buildRoute('v2_save_as_package_template_path','/api/v2/companies/:id/templates/package/:package_id');
buildRoute('v2_save_as_payments_template_path','/api/v2/companies/:id/templates/payments');
buildRoute('v2_save_as_agreement_template_path','/api/v2/companies/:id/templates/agreement');
buildRoute('v2_save_as_questionnaire_template_path','/api/v2/companies/:id/templates/questionnaire');
buildRoute('v2_save_as_brochure_template_path','/api/v2/companies/:id/templates/brochure');
buildRoute('v2_save_as_invoice_template_path','/api/v2/companies/:id/templates/invoice');
buildRoute('v2_save_as_proposal_template_path','/api/v2/companies/:id/templates/proposal');
buildRoute('v2_add_company_team_member_path','/api/v2/companies/:id/team_members');
buildRoute('v2_update_company_team_member_status_path','/api/v2/companies/:id/team_members');
buildRoute('v2_update_account_setup_assets_ready_path','/api/v2/companies/:id/setup');
buildRoute('v2_update_batch_upload_completed_path','/api/v2/companies/:id/update_batch');
buildRoute('v2_create_company_expense_path','/api/v2/companies/:id/expenses');
buildRoute('v2_create_booking_review_path','/api/v2/companies/:id/booking_reviews');
buildRoute('v2_company_remove_cover_image_path','/api/v2/companies/:id/cover_images/:image_id');
buildRoute('v2_company_remove_uploaded_file_path','/api/v2/companies/:id/assets/:file_id');
buildRoute('v2_remove_company_expense_path','/api/v2/companies/:id/expenses/:expense_id');
buildRoute('v2_company_delete_logo_or_icon_path','/api/v2/companies/:id/logo/:type');
buildRoute('v2_delete_voucher_lead_path','/api/v2/companies/:id/voucher/:source_file_id');
buildRoute('v2_remove_company_font_path','/api/v2/companies/:id/remove_company_font');
buildRoute('v2_company_set_default_cover_image_path','/api/v2/companies/:id/cover_images/default');
buildRoute('v2_company_update_project_types_path','/api/v2/companies/:id/project_types');
buildRoute('v2_company_get_project_types_path','/api/v2/companies/:id/project_types');
buildRoute('v2_company_update_lead_sources_path','/api/v2/companies/:id/lead_sources');
buildRoute('v2_update_client_portal_configuration_path','/api/v2/companies/:id/client_portal/configuration');
buildRoute('v2_company_sms_enabled_path','/api/v2/companies/:id/sms_enabled');
buildRoute('v2_company_sms_payment_reminders_enabled_path','/api/v2/companies/:id/sms_payment_reminders_enabled');
buildRoute('v2_contact_enrichment_enabled_path','/api/v2/companies/:id/contact_enrichment_enabled');
buildRoute('v2_send_client_portal_link_path','/api/v2/companies/:id/client_portal/email');
buildRoute('v2_update_company_url_prefix_path','/api/v2/companies/:id/company_url_prefix/:company_url_prefix');
buildRoute('v2_get_company_url_prefix_path','/api/v2/companies/:id/company_url_prefix');
buildRoute('v2_validate_company_url_prefix_path','/api/v2/companies/:id/company_url_prefix/:company_url_prefix');
buildRoute('v2_update_company_flow_theme_path','/api/v2/companies/:id/company_flow_theme');
buildRoute('v2_fetch_client_feedback_config_path','/api/v2/companies/:id/client_feedback/config');
buildRoute('v2_update_client_feedback_config_path','/api/v2/companies/:id/client_feedback/config');
buildRoute('v2_create_folder_path','/api/v2/companies/:id/folder');
buildRoute('v2_update_folder_path','/api/v2/companies/:id/folder/:folder_id');
buildRoute('v2_bulk_update_folders_path','/api/v2/companies/:id/folders/bulk_update');
buildRoute('v2_delete_folder_path','/api/v2/companies/:id/folder/:folder_id');
buildRoute('v2_company_suggested_brand_elements_path','/api/v2/companies/:id/company_suggested_brand_elements');
buildRoute('v2_company_add_service_unit_path','/api/v2/companies/:id/service_units');
buildRoute('v2_company_remove_service_unit_path','/api/v2/companies/:id/service_units');
buildRoute('v2_verify_domain_is_valid_path','/api/v2/companies/:id/verify_domain_is_valid');
buildRoute('v2_verify_custom_domain_available_path','/api/v2/companies/:id/verify_custom_domain_available');
buildRoute('v2_verify_custom_domain_dns_configured_path','/api/v2/companies/:id/verify_custom_domain_dns_configured');
buildRoute('v2_activate_custom_domain_path','/api/v2/companies/:id/activate_custom_domain');
buildRoute('v2_deactivate_custom_domain_path','/api/v2/companies/:id/deactivate_custom_domain');
buildRoute('v2_get_company_custom_domain_path','/api/v2/companies/:id/company_custom_domain');
buildRoute('v2_incoming_event_path','/api/v2/custom_domains/events');
buildRoute('v2_save_custom_project_fields_path','/api/v2/companies/:id/custom_project_fields');
buildRoute('v2_save_custom_contact_fields_path','/api/v2/companies/:id/custom_contact_fields');
buildRoute('v2_fetch_template_repository_path','/api/v2/templates_repository/:key');
buildRoute('v2_fetch_template_record_path','/api/v2/templates_repository_record/:id');
buildRoute('v2_fetch_template_records_path','/api/v2/templates_repository_records/find');
buildRoute('v2_filter_template_records_path','/api/v2/templates_repository_records/filter/:key');
buildRoute('v2_template_records_suggestions_path','/api/v2/templates_repository_records/suggestions');
buildRoute('v2_template_records_suggestions_filter_path','/api/v2/templates_repository_records/suggestions_filter');
buildRoute('v2_get_user_flow_templates_path','/api/v2/flow_gallery/user_flow_templates');
buildRoute('v2_import_templates_path','/api/v2/templates_repository_record/import');
buildRoute('v2_publish_template_path','/api/v2/templates_repository/template');
buildRoute('v2_republish_template_path','/api/v2/templates_repository/template');
buildRoute('v2_unpublish_template_path','/api/v2/templates_repository/template');
buildRoute('v2_fetch_repository_filters_summary_path','/api/v2/templates_repository/:key/filters_summary');
buildRoute('v2_edit_template_repository_path','/api/v2/templates_repository/:key');
buildRoute('v2_create_templates_with_specific_blocks_path','/api/v2/templates_repository/:template_id/custom_template');
buildRoute('v2_import_influencer_templates_path','/api/v2/templates_repository/influencer/:name');
buildRoute('v2_import_freelancers_union_template_path','/api/v2/templates_repository/freelancers_union/import');
buildRoute('v2_create_template_from_pdf_path','/api/v2/flow_from_pdf/template');
buildRoute('v2_create_instance_from_pdf_path','/api/v2/flow_from_pdf/instance');
buildRoute('v2_create_onboarding_instance_from_pdf_path','/api/v2/flow_from_pdf/onb_instance');
buildRoute('v2_fallback_create_flow_from_pdf_path','/api/v2/flow_from_pdf/websocket_fallback');
buildRoute('v2_gallery_filters_summary_path','/api/v2/flow_gallery/filters_summary');
buildRoute('v2_gallery_download_flow_path','/api/v2/flow_gallery/flow/:flow_id/download');
buildRoute('v2_company_get_spaces_path','/api/v2/companies/:id/spaces');
buildRoute('v2_company_update_spaces_path','/api/v2/companies/:id/spaces');
buildRoute('v2_company_create_contact_form_path','/api/v2/companies/:id/contact_form');
buildRoute('v2_company_contact_form_create_question_path','/api/v2/companies/:id/contact_form/:contact_form_id/questions');
buildRoute('v2_company_publish_contact_form_path','/api/v2/companies/:id/contact_form/:contact_form_id/publish');
buildRoute('v2_company_publish_contact_forms_lead_source_path','/api/v2/companies/:id/contact_forms/lead_source/publish');
buildRoute('v2_company_update_contact_form_path','/api/v2/companies/:id/contact_form/:contact_form_id');
buildRoute('v2_company_contact_form_update_questions_order_path','/api/v2/companies/:id/contact_form/:contact_form_id/update_questions_order');
buildRoute('v2_company_contact_form_update_question_path','/api/v2/companies/:id/contact_form/:contact_form_id/questions/:question_id');
buildRoute('v2_set_contact_form_redirect_url_path','/api/v2/companies/:id/contact_form/:contact_form_id/redirect_url');
buildRoute('v2_company_contact_form_update_style_path','/api/v2/companies/:id/contact_form/:contact_form_id/update_style');
buildRoute('v2_set_contact_form_captcha_type_path','/api/v2/companies/:id/contact_form/:contact_form_id/captcha');
buildRoute('v2_update_inquiry_recipient_path','/api/v2/companies/:id/contact_form/:contact_form_id/inquiry_recipient');
buildRoute('v2_copy_contact_form_example_to_company_path','/api/v2/companies/:id/contact_form_examples/:example_key');
buildRoute('v2_company_delete_contact_form_path','/api/v2/companies/:id/contact_form/:contact_form_id');
buildRoute('v2_company_contact_form_delete_question_path','/api/v2/companies/:id/contact_form/:contact_form_id/questions/:question_id');
buildRoute('v2_company_contact_form_path','/api/v2/companies/:id/contact_form/:contact_form_id');
buildRoute('v2_company_contact_form_preview_path','/api/v2/companies/:id/contact_form/:contact_form_id/preview');
buildRoute('v2_company_embed_contact_form_path','/api/v2/companies/:id/contact_form/:contact_form_id/embed');
buildRoute('v2_delete_proposal_template_path','/api/v2/companies/:id/vendor_proposal/:proposal_template_id');
buildRoute('v2_company_get_payable_path','/api/v2/companies/:id/payable/:payable_type/:payable_id');
buildRoute('v2_company_payable_calculate_tax_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/proposal/tax');
buildRoute('v2_company_payable_toggle_payment_recurring_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments/toggle_recurring');
buildRoute('v2_company_payable_update_payment_recurring_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments/update_recurring');
buildRoute('v2_company_add_payable_path','/api/v2/companies/:id/payable/:payable_type');
buildRoute('v2_company_add_payable_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/service');
buildRoute('v2_company_add_payable_package_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package/:package_id/service');
buildRoute('v2_company_add_payable_package_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package');
buildRoute('v2_company_add_payable_payment_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments');
buildRoute('v2_company_save_payable_payments_container_as_template_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments/template');
buildRoute('v2_company_save_payable_agreement_as_template_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/agreement/template');
buildRoute('v2_company_update_payable_packages_order_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/packages/order');
buildRoute('v2_company_update_payable_image_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/image');
buildRoute('v2_company_update_payable_proposal_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal');
buildRoute('v2_company_update_payable_package_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/packages/:package_id');
buildRoute('v2_company_update_payable_package_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/packages/:package_id/services/:service_id');
buildRoute('v2_company_update_payable_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/services/:service_id');
buildRoute('v2_update_payable_package_service_image_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/:package_id/services/:service_id/image');
buildRoute('v2_update_payable_standalone_service_image_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/services/:service_id/image');
buildRoute('v2_company_update_payable_services_order_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package/:package_id/services');
buildRoute('v2_company_move_payable_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/services/move/:service_id');
buildRoute('v2_company_update_payable_packages_and_services_order_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/order');
buildRoute('v2_company_toggle_payable_all_packages_tax_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/toggle_tax');
buildRoute('v2_company_update_payable_path','/api/v2/companies/:id/payable/:payable_type/:payable_id');
buildRoute('v2_company_update_payable_payment_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments/:payment_id');
buildRoute('v2_company_set_payable_payments_by_template_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments_by_template');
buildRoute('v2_company_update_payable_agreement_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/agreement');
buildRoute('v2_company_set_payable_agreement_by_template_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/agreement_by_template');
buildRoute('v2_delete_payable_template_path','/api/v2/companies/:id/payable/:payable_type/:payable_id');
buildRoute('v2_company_delete_payable_package_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package/:package_id');
buildRoute('v2_company_delete_payable_package_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package/:package_id/services/:service_id');
buildRoute('v2_company_delete_payable_service_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/services/:service_id');
buildRoute('v2_company_delete_payable_package_service_image_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposal/package/:package_id/services/:service_id/image');
buildRoute('v2_company_delete_payable_standalone_service_image_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/vendor_proposalservices/:service_id/image');
buildRoute('v2_company_delete_payable_payment_path','/api/v2/companies/:id/payable/:payable_type/:payable_id/payments/:payment_id');
buildRoute('v2_company_get_package_path','/api/v2/companies/:id/package/:package_id');
buildRoute('v2_flows_get_flow_package_path','/api/v2/flow_package/:flow_content_id/:block_id');
buildRoute('v2_company_get_package_usage_path','/api/v2/companies/:id/package/:package_id/usage');
buildRoute('v2_company_create_package_path','/api/v2/companies/:id/package');
buildRoute('v2_company_package_additional_service_path','/api/v2/companies/:id/package/:package_id/service');
buildRoute('v2_company_update_package_path','/api/v2/companies/:id/packages/:package_id');
buildRoute('v2_company_toggle_package_tax_path','/api/v2/companies/:id/packages/:package_id/tax');
buildRoute('v2_company_toggle_package_svc_path','/api/v2/companies/:id/packages/:package_id/svc');
buildRoute('v2_company_update_service_on_package_path','/api/v2/companies/:id/packages/:package_id/services/:service_id');
buildRoute('v2_update_services_order_on_package_template_path','/api/v2/companies/:id/packages/:package_id/services');
buildRoute('v2_update_service_image_on_package_template_path','/api/v2/companies/:id/packages/:package_id/services/:service_id/image');
buildRoute('v2_delete_package_template_path','/api/v2/companies/:id/package/:package_template_id');
buildRoute('v2_company_delete_service_on_package_path','/api/v2/companies/:id/package/:package_id/services/:service_id');
buildRoute('v2_delete_service_image_on_package_template_path','/api/v2/companies/:id/package/:package_id/services/:service_id/image');
buildRoute('v2_company_get_agreement_path','/api/v2/companies/:id/agreement/:agreement_id');
buildRoute('v2_company_create_agreement_path','/api/v2/companies/:id/agreement');
buildRoute('v2_company_update_agreement_path','/api/v2/companies/:id/agreements/:agreement_id');
buildRoute('v2_company_set_default_agreement_path','/api/v2/companies/:id/agreements/:agreement_id/default');
buildRoute('v2_update_agreement_with_file_path','/api/v2/companies/:id/agreements/:agreement_id/update_agreement_with_file');
buildRoute('v2_company_update_agreement_image_path','/api/v2/companies/:id/agremeents/:agreement_id/image');
buildRoute('v2_delete_agreement_template_path','/api/v2/companies/:id/agreement/:agreement_template_id');
buildRoute('v2_company_get_brochure_path','/api/v2/companies/:id/brochure/:brochure_id');
buildRoute('v2_company_redirect_to_brochure_image_path','/api/v2/companies/:id/brochure/:brochure_id/image');
buildRoute('v2_company_create_brochure_path','/api/v2/companies/:id/brochure');
buildRoute('v2_company_brochure_template_create_brochure_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposals');
buildRoute('v2_company_brochure_template_create_brochure_a_la_carte_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte');
buildRoute('v2_company_add_service_to_brochure_a_la_carte_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services');
buildRoute('v2_company_brochure_template_add_package_to_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/packages/:package_id');
buildRoute('v2_company_brochure_template_add_package_additional_service_to_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services');
buildRoute('v2_company_brochure_template_add_service_to_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/services');
buildRoute('v2_company_brochure_template_create_greeting_path','/api/v2/companies/:id/brochure/:brochure_id/greeting');
buildRoute('v2_company_template_create_brochure_template_from_old_path','/api/v2/companies/:id/brochure/:brochure_id/convert');
buildRoute('v2_company_update_brochure_path','/api/v2/companies/:id/brochure/:brochure_id');
buildRoute('v2_company_brochure_template_update_brochure_a_la_carte_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte');
buildRoute('v2_company_update_service_on_brochure_a_la_carte_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services/:service_id');
buildRoute('v2_company_brochure_template_update_brochure_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id');
buildRoute('v2_company_brochure_template_update_brochure_proposals_order_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposals/order');
buildRoute('v2_company_brochure_template_update_service_on_proposal_of_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/services/:service_id');
buildRoute('v2_company_brochure_template_update_package_on_proposal_of_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id');
buildRoute('v2_company_brochure_template_update_services_order_on_brochure_package_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposals/:brochure_proposal_id/packages/:package_id/services');
buildRoute('v2_update_services_order_in_brochure_a_la_carte_template_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services');
buildRoute('v2_company_brochure_template_update_service_on_package_of_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_company_brochure_template_update_service_image_on_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id/image');
buildRoute('v2_company_brochure_template_update_service_image_on_a_la_carte_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services/:service_id/image');
buildRoute('v2_company_brochure_template_update_brochure_proposal_image_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/image');
buildRoute('v2_company_brochure_template_update_brochure_a_la_carte_image_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/image');
buildRoute('v2_company_brochure_template_update_greeting_path','/api/v2/companies/:id/brochure/:brochure_id/greeting');
buildRoute('v2_company_brochure_template_update_greeting_image_path','/api/v2/companies/:id/brochure/:brochure_id/greeting/image');
buildRoute('v2_delete_brochure_template_path','/api/v2/companies/:id/brochure/:brochure_template_id');
buildRoute('v2_company_brochure_template_delete_brochure_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposals/:brochure_proposal_id');
buildRoute('v2_company_brochure_template_delete_brochure_a_la_carte_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte');
buildRoute('v2_company_delete_service_on_brochure_a_la_carte_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services/:service_id');
buildRoute('v2_company_brochure_template_delete_service_from_brochure_proposal_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/services/:service_id');
buildRoute('v2_company_brochure_template_delete_service_on_package_from_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/proposal/packages/:package_id/services/:service_id');
buildRoute('v2_company_brochure_template_delete_package_on_proposal_of_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/packages/:package_id');
buildRoute('v2_company_brochure_template_delete_service_image_on_brochure_path','/api/v2/companies/:id/brochure/:brochure_id/brochure_proposal/:brochure_proposal_id/packages/:package_id/services/:service_id/image');
buildRoute('v2_company_brochure_template_delete_service_image_on_a_la_carte_path','/api/v2/companies/:id/brochure/:brochure_id/a_la_carte/services/:service_id/image');
buildRoute('v2_company_brochure_template_delete_greeting_path','/api/v2/companies/:id/brochure/:brochure_id/greeting');
buildRoute('v2_company_get_questionnaire_path','/api/v2/companies/:id/questionnaire/:questionnaire_id');
buildRoute('v2_company_create_questionnaire_path','/api/v2/companies/:id/questionnaire');
buildRoute('v2_company_questionnaire_template_create_question_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/questions');
buildRoute('v2_company_questionnaire_template_create_event_questions_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/event/questions');
buildRoute('v2_company_update_questionnaire_path','/api/v2/companies/:id/questionnaire/:questionnaire_id');
buildRoute('v2_company_update_questionnaire_image_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/image');
buildRoute('v2_company_questionnaire_update_questions_order_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/update_questions_order');
buildRoute('v2_company_questionnaire_template_update_question_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/questions/:question_id');
buildRoute('v2_delete_questionnaire_template_path','/api/v2/companies/:id/questionnaire/:questionnaire_template_id');
buildRoute('v2_delete_questionnaire_template_question_path','/api/v2/companies/:id/questionnaire/:questionnaire_id/questions/:question_id');
buildRoute('v2_company_get_email_path','/api/v2/companies/:id/email/:email_id');
buildRoute('v2_get_populated_email_template_path','/api/v2/companies/:id/email/:email_id/sf_populated');
buildRoute('v2_company_create_email_path','/api/v2/companies/:id/email');
buildRoute('v2_company_update_email_path','/api/v2/companies/:id/emails/:email_id');
buildRoute('v2_delete_email_template_path','/api/v2/companies/:id/email/:email_template_id');
buildRoute('v2_delete_payments_template_path','/api/v2/companies/:id/payments/:payments_template_id');
buildRoute('v2_get_image_path','/api/v2/images/:id');
buildRoute('v2_update_image_path','/api/v2/images/:id');
buildRoute('v2_get_email_preview_path','/api/v2/preview_email');
buildRoute('v2_get_smart_fields_path','/api/v2/smart_fields');
buildRoute('v2_user_workspace_files_malkut_path','/api/v2/malkut/users/:user_id/workspace_files_malkut');
buildRoute('v2_malkut_userDetails_path','/api/v2/malkut/user_details/email/:email');
buildRoute('v2_malkut_searchUser_path','/api/v2/malkut/user_details/search/:email');
buildRoute('v2_malkut_fetchUserImages_path','/api/v2/malkut/user_details/fetch_user_images');
buildRoute('v2_malkut_client_users_by_email_path','/api/v2/malkut/client_user_details/email/:email');
buildRoute('v2_malkut_user_templates_path','/api/v2/malkut/users/:user_id/user_templates');
buildRoute('v2_malkut_user_flow_templates_path','/api/v2/malkut/users/:user_id/user_flow_templates');
buildRoute('v2_malkut_transfer_user_templates_path','/api/v2/malkut/user_templates');
buildRoute('v2_malkut_transfer_user_flow_templates_path','/api/v2/malkut/user_flow_templates');
buildRoute('v2_malkut_update_user_templates_to_inject_path','/api/v2/malkut/update_user_templates_to_inject');
buildRoute('v2_malkut_send_reset_pwd_email_path','/api/v2/malkut/send_reset_pwd_email/:user_id');
buildRoute('v2_malkut_mark_tracking_activity_completed_path','/api/v2/malkut/mark_activity_tracking_as_completed/:user_id');
buildRoute('v2_malkut_mark_setup_guide_as_completed_path','/api/v2/malkut/mark_setup_guide_as_completed/:user_id');
buildRoute('v2_malkut_block_incoming_inquiries_path','/api/v2/malkut/block_incoming_inquiries/:user_id');
buildRoute('v2_malkut_toggle_finance_app_flow_of_funds_path','/api/v2/malkut/finance_app_payouts/:user_id');
buildRoute('v2_malkut_close_member_balance_account_path','/api/v2/malkut/finance_account/:user_id/close');
buildRoute('v2_malkut_force_expire_password_path','/api/v2/malkut/force_expire_password/:user_id');
buildRoute('v2_malkut_delete_company_services_path','/api/v2/malkut/:company_id/delete_services');
buildRoute('v2_malkut_prepare_request_documents_email_path','/api/v2/malkut/request_documents_email/:user_id');
buildRoute('v2_malkut_request_documents_path','/api/v2/malkut/request_documents');
buildRoute('v2_malkut_get_flow_yaml_path','/api/v2/malkut/flow_yaml/:flow_id');
buildRoute('v2_malkut_load_flow_yaml_path','/api/v2/malkut/load_flow_yaml');
buildRoute('v2_malkut_load_template_data_path','/api/v2/malkut/load_template_data');
buildRoute('v2_malkut_searchGlobal_path','/api/v2/malkut/search_global');
buildRoute('v2_malkut_userExtraDetails_path','/api/v2/malkut/user_extra_details/email/:email');
buildRoute('v2_malkut_failed_transfers_new_path','/api/v2/malkut/transfers/failed_new');
buildRoute('v2_malkut_unverified_recipient_path','/api/v2/malkut/recipients/unverified');
buildRoute('v2_malkut_users_graph_path','/api/v2/malkut/graph/users');
buildRoute('v2_malkut_get_blocked_vendors_path','/api/v2/malkut/blocked_vendors');
buildRoute('v2_malkut_get_disputed_payments_path','/api/v2/malkut/disputed_payments');
buildRoute('v2_malkut_get_unverified_objects_path','/api/v2/malkut/unverified_objects');
buildRoute('v2_malkut_get_blocked_clients_path','/api/v2/malkut/blocked_clients');
buildRoute('v2_malkut_get_forter_payments_path','/api/v2/malkut/forter_payments');
buildRoute('v2_malkut_get_blocked_user_full_info_path','/api/v2/malkut/blocked_users/:user_id');
buildRoute('v2_malkut_add_payment_admin_edit_path','/api/v2/malkut/payments/:payment_id/admin_edits');
buildRoute('v2_malkut_get_all_pricing_plans_path','/api/v2/malkut/pricing_plans');
buildRoute('v2_malkut_cancel_pricing_plan_path','/api/v2/malkut/companies/:company_id/pricing_plan/cancel');
buildRoute('v2_malkut_revoke_pricing_plan_path','/api/v2/malkut/companies/:company_id/pricing_plan/revoke');
buildRoute('v2_malkut_send_subscription_receipt_path','/api/v2/malkut/companies/:company_id/pricing_plan/send_receipt');
buildRoute('v2_malkut_disable_2fa_login_path','/api/v2/malkut/users/:user_id/disable_2fa_login');
buildRoute('v2_malkut_admin_login_path','/api/v2/malkut/users/:user_id/tokens');
buildRoute('v2_get_user_info_path','/api/v2/malkut/users/:user_id/info');
buildRoute('v2_login_malkut_user_path','/api/v2/malkut/users/login_malkut_user');
buildRoute('v2_malkut_extend_trial_period_path','/api/v2/malkut/companies/:company_id/extend_trial');
buildRoute('v2_malkut_update_company_type_path','/api/v2/malkut/companies/:company_id/update_company_type');
buildRoute('v2_malkut_update_company_location_path','/api/v2/malkut/companies/:company_id/location');
buildRoute('v2_malkut_company_toggle_ach_refunds_path','/api/v2/malkut/companies/:company_id/toggle_ach_refunds');
buildRoute('v2_malkut_company_force_toggle_otam_on_path','/api/v2/malkut/companies/:company_id/force_toggle_otam_on');
buildRoute('v2_malkut_update_user_type_path','/api/v2/malkut/users/:user_id/update_user_type');
buildRoute('v2_malkut_update_is_pro_path','/api/v2/malkut/users/:user_id/update_is_pro');
buildRoute('v2_malkut_update_is_bookkeeper_path','/api/v2/malkut/users/:user_id/update_is_bookkeeper');
buildRoute('v2_malkut_remove_account_path','/api/v2/malkut/users/:user_id/remove_account');
buildRoute('v2_malkut_nylas_force_basic_auth_path','/api/v2/malkut/users/:user_id/nylas_force_basic_auth');
buildRoute('v2_malkut_nylas_allow_unknown_provider_path','/api/v2/malkut/users/:user_id/nylas_allow_unknown_provider');
buildRoute('v2_malkut_update_account_fraud_agent_path','/api/v2/malkut/users/:user_id/update_account_fraud_agent');
buildRoute('v2_malkut_update_account_fraud_research_stage_path','/api/v2/malkut/users/:user_id/update_account_fraud_research_stage');
buildRoute('v2_malkut_delete_calendar_integration_path','/api/v2/malkut/users/:user_id/calendar_integration');
buildRoute('v2_malkut_delete_email_integration_path','/api/v2/malkut/users/:user_id/email_integration');
buildRoute('v2_malkut_resync_gcal_events_path','/api/v2/malkut/users/:user_id/resync_gcal');
buildRoute('v2_malkut_resync_imported_expenses_path','/api/v2/malkut/users/:user_id/resync_expenses');
buildRoute('v2_malkut_send_feedback_form_notification_path','/api/v2/malkut/users/:user_id/send_feedback_form_notification');
buildRoute('v2_malkut_update_company_bankruptcy_path','/api/v2/malkut/users/:user_id/update_bankruptcy');
buildRoute('v2_malkut_unsync_all_qb_payments_path','/api/v2/malkut/users/:user_id/qb_payments_unsync');
buildRoute('v2_malkut_delete_user_profile_image_path','/api/v2/malkut/users/:user_id/delete_profile_image');
buildRoute('v2_malkut_get_managed_accounts_path','/api/v2/malkut/managed_accounts');
buildRoute('v2_malkut_update_managed_accounts_status_path','/api/v2/malkut/update_managed_accounts');
buildRoute('v2_malkut_refresh_managed_accounts_status_path','/api/v2/malkut/refresh_managed_accounts');
buildRoute('v2_malkut_update_single_managed_account_status_path','/api/v2/malkut/update_managed_account');
buildRoute('v2_malkut_upload_identification_to_stripe_path','/api/v2/malkut/upload_identification_stripe');
buildRoute('v2_malkut_update_managed_account_missing_fields_path','/api/v2/malkut/update_managed_account_fields');
buildRoute('v2_malkut_update_account_bank_info_path','/api/v2/malkut/update_account_bank_info');
buildRoute('v2_malkut_update_stripe_account_details_path','/api/v2/malkut/update_stripe_account_details');
buildRoute('v2_malkut_update_company_address_with_stripe_path','/api/v2/malkut/malkut_update_company_address_stripe/:id');
buildRoute('v2_malkut_transfer_funds_from_honeybook_path','/api/v2/malkut/companies/:company_id/transfer_funds_from_hb');
buildRoute('v2_malkut_pull_funds_from_member_bank_path','/api/v2/malkut/companies/:company_id/pull_funds_from_member');
buildRoute('v2_malkut_republish_social_tags_config_path','/api/v2/malkut/companies/:company_id/republish_social_tags_config');
buildRoute('v2_malkut_enable_custom_domain_path','/api/v2/malkut/companies/:company_id/enable_custom_domain');
buildRoute('v2_malkut_reset_url_prefix_path','/api/v2/malkut/companies/:company_id/reset_url_prefix');
buildRoute('v2_malkut_verify_custom_domain_dns_configured_path','/api/v2/malkut/companies/:company_id/verify_custom_domain_dns_configured');
buildRoute('v2_malkut_check_easy_connect_availability_path','/api/v2/malkut/companies/:company_id/check_easy_connect_availability');
buildRoute('v2_malkut_enable_team_member_path','/api/v2/malkut/companies/:company_id/enable_team_member/:member_id');
buildRoute('v2_malkut_send_bank_info_missing_email_path','/api/v2/malkut/send_bank_missing');
buildRoute('v2_user_identification_image_path','/api/v2/malkut/:id/identification');
buildRoute('v2_malkut_download_identification_document_path','/api/v2/malkut/companies/:company_id/download_identification_document');
buildRoute('v2_malkut_fetch_identification_document_path','/api/v2/malkut/users/:id/fetch_identification_document');
buildRoute('v2_malkut_delete_identification_document_path','/api/v2/malkut/companies/:company_id/delete_identification_document');
buildRoute('v2_malkut_update_disputed_payment_path','/api/v2/malkut/disputed_payments/:id');
buildRoute('v2_malkut_prepare_reminder_disputed_payment_path','/api/v2/malkut/disputed_payment_reminder/:id');
buildRoute('v2_malkut_send_reminder_disputed_payment_path','/api/v2/malkut/disputed_payment_reminder/:id');
buildRoute('v2_malkut_set_dispute_recollection_flow_path','/api/v2/malkut/disputed_payments_recollect/:id');
buildRoute('v2_malkut_revert_recollected_dispute_funds_path','/api/v2/malkut/disputed_payments_recollect_revert/:id');
buildRoute('v2_malkut_set_dispute_mitigation_path','/api/v2/malkut/disputed_payments/:id/set_dispute_mitigation');
buildRoute('v2_malkut_loans_path','/api/v2/malkut/loans');
buildRoute('v2_malkut_refresh_elastic_search_path','/api/v2/malkut/:id/refresh_search');
buildRoute('v2_user_refresh_elastic_search_path','/api/v2/users/:id/refresh_search');
buildRoute('v2_malkut_get_vendor_contacts_path','/api/v2/malkut/users/contacts');
buildRoute('v2_malkut_update_dispute_agent_path','/api/v2/malkut/update_dispute_agent/:id');
buildRoute('v2_malkut_get_promo_codes_path','/api/v2/malkut/promo_codes');
buildRoute('v2_malkut_create_promo_code_path','/api/v2/malkut/promo_code');
buildRoute('v2_malkut_bulk_create_promo_code_path','/api/v2/malkut/bulk_promo_code');
buildRoute('v2_malkut_update_promo_code_path','/api/v2/malkut/promo_code/:promo_code_id');
buildRoute('v2_malkut_delete_promo_code_path','/api/v2/malkut/promo_code/:promo_code_id');
buildRoute('v2_malkut_create_custom_pricing_plan_path','/api/v2/malkut/companies/:company_id/pricing_plan');
buildRoute('v2_malkut_reinstate_pricing_plan_path','/api/v2/malkut/companies/:company_id/reinstate_pricing_plan');
buildRoute('v2_malkut_change_user_email_path','/api/v2/malkut/:user_id/email');
buildRoute('v2_malkut_acknowledge_forter_payment_path','/api/v2/malkut/forter_payments/:id');
buildRoute('v2_malkut_set_high_transaction_threshold_path','/api/v2/malkut/set_high_tnx');
buildRoute('v2_malkut_update_user_fraud_status_path','/api/v2/malkut/update_fraud_status');
buildRoute('v2_malkut_update_user_account_status_path','/api/v2/malkut/update_account_status');
buildRoute('v2_malkut_update_user_account_status_cs_path','/api/v2/malkut/update_account_status_cs');
buildRoute('v2_malkut_add_internal_note_path','/api/v2/malkut/add_internal_note');
buildRoute('v2_malkut_update_account_payout_state_path','/api/v2/malkut/update_account_payout_state');
buildRoute('v2_malkut_update_payment_fraud_status_verified_path','/api/v2/malkut/verified_payments');
buildRoute('v2_malkut_refund_blocked_payment_path','/api/v2/malkut/refund_blocked_payment');
buildRoute('v2_malkut_toggle_forter_escalation_path','/api/v2/malkut/toggle_forter_escalation');
buildRoute('v2_malkut_pay_referral_commissions_path','/api/v2/malkut/pay_referral_commissions');
buildRoute('v2_malkut_mark_referral_commissions_as_paid_path','/api/v2/malkut/mark_referral_commissions_as_paid');
buildRoute('v2_malkut_create_manual_referral_path','/api/v2/malkut/create_manual_referral');
buildRoute('v2_malkut_fetch_referral_campaigns_path','/api/v2/malkut/fetch_referral_campaigns');
buildRoute('v2_malkut_update_referral_campaign_path','/api/v2/malkut/update_referral_campaign');
buildRoute('v2_malkut_update_referral_code_path','/api/v2/malkut/update_referral_code');
buildRoute('v2_malkut_referral_code_exists_path','/api/v2/malkut/referral_code_exists');
buildRoute('v2_malkut_change_user_campaign_path','/api/v2/malkut/change_user_campaign');
buildRoute('v2_malkut_get_referral_user_path','/api/v2/malkut/referral_user/email/:email');
buildRoute('v2_get_pending_commission_referral_details_path','/api/v2/malkut/:referral_id/pending_commission_referral_details');
buildRoute('v2_malkut_update_referral_fraud_status_path','/api/v2/malkut/referral_fraud_status');
buildRoute('v2_malkut_get_restorable_objects_path','/api/v2/malkut/get_restorable_objects');
buildRoute('v2_malkut_restore_object_path','/api/v2/malkut/restore_object');
buildRoute('v2_get_malkut_url_path','/api/v2/admin/get_malkut_url');
buildRoute('v2_malkut_retry_transfer_path','/api/v2/malkut/transfers/failed');
buildRoute('v2_malkut_add_existing_vendor_to_company_path','/api/v2/malkut/companies/:company_id/team');
buildRoute('v2_malkut_pay_managed_account_path','/api/v2/malkut/pay_managed_account');
buildRoute('v2_malkut_refund_hb_fee_path','/api/v2/malkut/refund_hb_fee');
buildRoute('v2_malkut_toggle_ach_path','/api/v2/malkut/companies/:id/ach');
buildRoute('v2_malkut_toggle_membership_path','/api/v2/malkut/companies/:id/membership');
buildRoute('v2_malkut_toggle_project_fields_path','/api/v2/malkut/companies/:id/project_fields');
buildRoute('v2_malkut_toggle_templates_injection_path','/api/v2/malkut/users/:id/templates_injection');
buildRoute('v2_malkut_set_as_owner_path','/api/v2/malkut/companies/:id/owner/:member_id');
buildRoute('v2_malkut_set_free_pricing_plan_path','/api/v2/malkut/companies/:id/free_pricing_plan');
buildRoute('v2_malkut_enable_company_account_setup_path','/api/v2/malkut/companies/:id/account_setup');
buildRoute('v2_malkut_toggle_import_expenses_path','/api/v2/malkut/companies/:id/expenses');
buildRoute('v2_malkut_set_discounted_fee_path','/api/v2/malkut/companies/:id/discounted_fee');
buildRoute('v2_malkut_malkut_promo_code_path','/api/v2/malkut/companies/:id/malkut_promo_code');
buildRoute('v2_malkut_extend_membership_path','/api/v2/malkut/companies/:id/extend_membership');
buildRoute('v2_malkut_revoke_discounted_fee_path','/api/v2/malkut/companies/:id/revoke_discounted_fee');
buildRoute('v2_malkut_copy_template_from_company_path','/api/v2/malkut/companies/:id/copy_template_from_company');
buildRoute('v2_malkut_set_as_main_brand_path','/api/v2/malkut/companies/:id/set_as_main_brand');
buildRoute('v2_malkut_toggle_company_archive_path','/api/v2/malkut/companies/:id/archive');
buildRoute('v2_malkut_set_manually_transferred_path','/api/v2/malkut/transfers/failed');
buildRoute('v2_malkut_set_transfer_complete_path','/api/v2/malkut/transfers/incomplete');
buildRoute('v2_malkut_set_ach_refund_success_path','/api/v2/malkut/transfers/set_ach_refund_success');
buildRoute('v2_malkut_get_contact_form_spam_path','/api/v2/malkut/contact_form_spam');
buildRoute('v2_download_emails_attachment_csv_path','/api/v2/malkut/users/:id/download_emails_attachment_csv');
buildRoute('v2_get_verifalia_status_for_user_path','/api/v2/malkut/users/:id/get_verifalia_status');
buildRoute('v2_run_verifalia_for_contacts_path','/api/v2/malkut/users/:id/run_verifalia_for_contacts');
buildRoute('v2_malkut_toggle_allow_none_gmail_bulk_email_path','/api/v2/malkut/companies/:id/allow_none_gmail_bulk_email');
buildRoute('v2_malkut_update_as_spam_bulk_path','/api/v2/malkut/spam/bulk');
buildRoute('v2_malkut_update_as_spam_path','/api/v2/malkut/spam/:spam_id');
buildRoute('v2_malkut_update_as_inquiry_path','/api/v2/malkut/inquiry/:inquiry_id');
buildRoute('v2_malkut_update_disable_client_pvl_requests_path','/api/v2/malkut/users/:id/update_disable_client_pvl_requests');
buildRoute('v2_malkut_set_phone_number_for_2fa_path','/api/v2/malkut/users/:id/set_phone_number_for_2fa');
buildRoute('v2_malkut_set_new_status_flag_path','/api/v2/malkut/users/:id/set_new_status_flag');
buildRoute('v2_malkut_validate_email_path','/api/v2/malkut/administration/validations/emails/:email');
buildRoute('v2_malkut_override_forter_pre_charge_path','/api/v2/malkut/administration/override_forter/files/:file_id');
buildRoute('v2_malkut_remove_object_mappings_path','/api/v2/malkut/administration/object_mappings/:workspace_id');
buildRoute('v2_malkut_create_user_path','/api/v2/malkut/administration/create_malkut_user/');
buildRoute('v2_malkut_disable_user_path','/api/v2/malkut/administration/disable_malkut_user/');
buildRoute('v2_malkut_add_pro_integration_path','/api/v2/malkut/users/:id/add_pro_integration');
buildRoute('v2_malkut_get_user_audits_path','/api/v2/malkut/users/:id/get_user_audits');
buildRoute('v2_malkut_get_email_statuses_path','/api/v2/malkut/users/get_email_statuses');
buildRoute('v2_malkut_get_mail_s3_path','/api/v2/malkut/users/s3_key');
buildRoute('v2_malkut_contact_form_url_exists_path','/api/v2/malkut/contact_form_url_exists');
buildRoute('v2_malkut_update_contact_form_url_path','/api/v2/malkut/companies/:company_id/update_contact_form_url');
buildRoute('v2_malkut_migrate_contact_form_path','/api/v2/malkut/companies/:company_id/contact_forms/:contact_form_id/migrate');
buildRoute('v2_malkut_auto_suggest_ge_info_path','/api/v2/malkut/auto_suggest/get_info');
buildRoute('v2_malkut_auto_suggest_get_info_by_workspace_path','/api/v2/malkut/auto_suggest/get_info_by_workspace');
buildRoute('v2_malkut_auto_suggest_check_user_path','/api/v2/malkut/users/:id/auto_suggest_check_user');
buildRoute('v2_malkut_reset_logo_path','/api/v2/malkut/companies/:company_id/reset_logo');
buildRoute('v2_malkut_reset_icon_path','/api/v2/malkut/companies/:company_id/reset_icon');
buildRoute('v2_malkut_get_external_user_messages_path','/api/v2/malkut/external_user_messages');
buildRoute('v2_malkut_process_external_user_messages_path','/api/v2/malkut/external_user_messages/:id');
buildRoute('v2_malkut_delete_external_user_messages_path','/api/v2/malkut/external_user_messages/:id');
buildRoute('v2_malkut_delete_spam_emails_path','/api/v2/malkut/delete_spam_emails');
buildRoute('v2_malkut_first_public_payments_violation_path','/api/v2/malkut/fraud/first_public_payments_violation');
buildRoute('v2_malkut_franchise_user_copy_path','/api/v2/malkut/franchise_user_copy');
buildRoute('v2_malkut_approve_referrals_path','/api/v2/malkut/approve_referrals');
buildRoute('v2_malkut_get_global_feature_variations_path','/api/v2/malkut/global_feature_variations');
buildRoute('v2_malkut_set_global_feature_variations_path','/api/v2/malkut/global_feature_variations');
buildRoute('v2_malkut_create_global_feature_variations_path','/api/v2/malkut/global_feature_variations');
buildRoute('v2_malkut_get_hidden_coupons_path','/api/v2/malkut/hidden_recurly_coupon');
buildRoute('v2_malkut_add_hidden_coupon_path','/api/v2/malkut/hidden_recurly_coupon');
buildRoute('v2_malkut_remove_hidden_coupon_path','/api/v2/malkut/hidden_recurly_coupon');
buildRoute('v2_malkut_set_discounted_transaction_fees_path','/api/v2/malkut/account/:id/discounted_transaction_fees');
buildRoute('v2_malkut_get_active_recurring_files_details_path','/api/v2/malkut/account/:id/active_recurring_files_details');
buildRoute('v2_malkut_get_user_feature_plan_path','/api/v2/malkut/account/:id/feature_plan');
buildRoute('v2_malkut_update_user_feature_variation_path','/api/v2/malkut/account/:id/feature_variation');
buildRoute('v2_malkut_get_all_product_tips_path','/api/v2/malkut/product_tips');
buildRoute('v2_malkut_delete_product_tip_path','/api/v2/malkut/product_tips/:product_tip_id');
buildRoute('v2_malkut_update_product_tip_path','/api/v2/malkut/product_tips/:product_tip_id');
buildRoute('v2_malkut_create_product_tip_path','/api/v2/malkut/product_tips');
buildRoute('v2_malkut_get_all_home_resources_configs_path','/api/v2/malkut/home_resources');
buildRoute('v2_malkut_update_link_resource_path','/api/v2/malkut/home_resources_link');
buildRoute('v2_malkut_update_media_resource_path','/api/v2/malkut/home_resources_media');
buildRoute('v2_email_deliverability_test_start_test_path','/api/v2/email-deliverability-tests');
buildRoute('v2_email_deliverability_test_get_last_by_email_path','/api/v2/email-deliverability-tests/last');
buildRoute('v2_s3_widget_proxy_path','/api/v2/proxy/s3/widgets/companies/:company_id');
buildRoute('v2_resolve_link_path','/api/v2/links/:id/:link_token');
buildRoute('v2_regenerate_link_path','/api/v2/links/:id/:link_token/regenerate');
buildRoute('v2_mail_opened_path','/api/v2/links/mail_opened');
buildRoute('v2_lifecycle_marketing_link_path','/api/v2/lifecycle_marketing_link/:link_type');
buildRoute('stripe_notifications_path','/api/v2/stripenotifications');
buildRoute('handle_failed__path','/api/v2/stripefaildtransfer');
buildRoute('handle_managed_account_update_path','/api/v2/stripeaccountupdate');
buildRoute('handle_payment_dispute_path','/api/v2/stripepaymentdispute');
buildRoute('stripe_charge_webhook_path','/api/v2/stripe/charge');
buildRoute('stripe_payout_webhook_path','/api/v2/stripe/payout');
buildRoute('stripe_webhook_router_path','/api/v2/stripe/webhook');
buildRoute('appstore_status_update_notification_path','/api/v2/appstore/status_update_notification');
buildRoute('app_subscription_status_update_notification_path','/api/v2/app_subscripiton/status_update_notification');
buildRoute('biz_quiz_path','/api/v2/typeform/biz_quiz');
buildRoute('typeform_process_mapping_path','/api/v2/typeform/process_mapping');
buildRoute('typeform_breakthrough_grant_losers_path','/api/v2/typeform/breakthrough_grant_losers');
buildRoute('facebook_verify_webhook_path','/api/v2/facebook/lead');
buildRoute('facebook_check_subscription_path','/api/v2/facebook/check_subscription/:page_id');
buildRoute('v2_get_company_facebook_lead_pages_path','/api/v2/companies/:id/facebook_lead_pages');
buildRoute('facebook_lead_adds_path','/api/v2/facebook/lead');
buildRoute('facebook_subscribe_page_path','/api/v2/companies/:company_id/facebook/subscribe_page');
buildRoute('facebook_unsubscribe_page_path','/api/v2/companies/:company_id/facebook/unsubscribe_page/:page_id');
buildRoute('forter_event_path','/api/v2/forter/notification');
buildRoute('v2_create_client_pending_task_path','/api/v2/client_pending_task');
buildRoute('v2_check_client_pending_tasks_path','/api/v2/client_pending_tasks');
buildRoute('v2_cancel_client_pending_task_path','/api/v2/client_pending_tasks/:task_id');
buildRoute('v2_get_batch_emails_path','/api/v2/users/:id/batch_emails');
buildRoute('v2_get_batch_emails_status_path','/api/v2/users/:id/batch_emails/status');
buildRoute('v2_get_bulk_workspace_emails_path','/api/v2/users/:id/bulk_workspace_emails');
buildRoute('v2_get_allow_bulk_workspace_emails_path','/api/v2/users/:id/allow_bulk_workspace_emails');
buildRoute('v2_get_batch_email_contacts_path','/api/v2/users/:id/batch_emails/contacts');
buildRoute('v2_year_in_review_2022_path','/api/v2/users/:id/year_in_review_2022');
buildRoute('v2_year_in_review_2023_path','/api/v2/users/:id/year_in_review_2023');
buildRoute('v2_send_mail_path','/api/v2/users/:id/year_in_review_2023/send_mail');
buildRoute('v2_header_delight_path','/api/v2/users/:id/header_delight');
buildRoute('v2_header_delight_list_path','/api/v2/users/:id/header_delight_list');
buildRoute('v2_create_header_delight_path','/api/v2/users/:id/header_delight');
buildRoute('v2_update_header_delight_path','/api/v2/users/:id/header_delight/:header_delight_id');
buildRoute('v2_delete_header_delight_path','/api/v2/users/:id/header_delight/:header_delight_id');
buildRoute('v2_m_workspace_path','/api/v2/m/workspaces/:id');
buildRoute('v2_m_workspace_files_drafts_path','/api/v2/m/workspaces/:id/workspace_files/drafts');
buildRoute('v2_m_get_current_user_path','/api/v2/m/users/:id');
buildRoute('v2_m_mobile_app_handshake_path','/api/v2/m/users/:id/hey_mobile_app');
buildRoute('v2_m_update_mobile_user_profile_path','/api/v2/m/users/:id');
buildRoute('v2_m_get_setup_guide_steps_status_path','/api/v2/m/users/:id/setup_guide/steps/status');
buildRoute('v2_m_get_link_details_path','/api/v2/m/links/:id/:link_token');
buildRoute('v2_user_get_recent_mobile_notifications_path','/api/v2/m/notifications');
buildRoute('v2_user_get_multi_brand_mobile_notifications_count_path','/api/v2/m/account/notifications/count');
buildRoute('v2_mark_all_mobile_notifications_as_seen_path','/api/v2/m/notifications/seen');
buildRoute('v2_set_mobile_notification_seen_status_path','/api/v2/m/notifications/:notification_id/seen_status');
buildRoute('v2_get_mobile_device_settings_path','/api/v2/m/device/settings');
buildRoute('v2_toggle_push_notifications_for_device_path','/api/v2/m/device/notifications/toggle');
buildRoute('v2_device_is_alive_path','/api/v2/m/device/alive');
buildRoute('v2_get_flow_for_mobile_path','/api/v2/m/flow/:flow_id');
buildRoute('v2_m_view_desktop_email_path','/api/v2/m/users/:id/view_desktop_email');
buildRoute('v2_process_app_transaction_path','/api/v2/account/:account_id/account_app_subscription');
buildRoute('v2_intro_offer_eligibility_path','/api/v2/account/:account_id/intro_offer_eligibility');
buildRoute('v2_process_app_membership_transaction_path','/api/v2/account/:account_id/account_app_membership_subscription');
buildRoute('v2_process_app_membership_restore_path','/api/v2/account/:account_id/account_app_membership_restore');
buildRoute('v2_membership_intro_offer_eligibility_path','/api/v2/account/:account_id/membership_intro_offer_eligibility');
buildRoute('v2_get_feature_plan_for_account_path','/api/v2/account/:account_id/feature_plan');
buildRoute('v2_create_client_organization_path','/api/v2/client_organization');
buildRoute('v2_edit_client_organization_path','/api/v2/client_organization/:id');
buildRoute('v2_add_client_to_organization_path','/api/v2/client_organization/:id/add_client');
buildRoute('v2_get_client_organization_path','/api/v2/client_organization/:id');
buildRoute('v2_all_client_organizations_path','/api/v2/client_organizations');
buildRoute('v2_delete_client_organization_path','/api/v2/client_organization/:id');
buildRoute('v2_remove_client_from_organization_path','/api/v2/client_organization/:id/remove_client/:client_id');
buildRoute('v2_get_organization_payments_path','/api/v2/client_organization/:id/payments');
buildRoute('v2_preview_subscription_path','/api/v2/account/:account_id/subscription/preview');
buildRoute('v2_purchase_subscription_path','/api/v2/account/:account_id/subscription');
buildRoute('v2_cancel_subscription_path','/api/v2/account/:account_id/subscription/cancel');
buildRoute('v2_terminate_subscription_path','/api/v2/account/:account_id/subscription/terminate');
buildRoute('v2_pause_subscription_path','/api/v2/account/:account_id/subscription/pause');
buildRoute('v2_resume_subscription_path','/api/v2/account/:account_id/subscription/resume');
buildRoute('v2_get_billing_info_path','/api/v2/account/:account_id/subscription/billing_info');
buildRoute('v2_update_billing_info_path','/api/v2/account/:account_id/subscription/billing_info');
buildRoute('v2_sync_account_subscription_path','/api/v2/account/:account_id/subscription/sync');
buildRoute('v2_create_subscription_change_path','/api/v2/account/:account_id/subscription/change');
buildRoute('v2_preview_subscription_change_path','/api/v2/account/:account_id/subscription/change/preview');
buildRoute('v2_get_coupon_path','/api/v2/account/:account_id/coupons/:coupon_code');
buildRoute('v2_update_next_plan_period_path','/api/v2/account/:account_id/next_period');
buildRoute('v2_get_subscription_change_path','/api/v2/account/:account_id/subscription_change');
buildRoute('v2_list_account_invoices_path','/api/v2/account/:account_id/invoices');
buildRoute('v2_preview_renewal_path','/api/v2/account/:account_id/preview_renewal');
buildRoute('v2_plans_data_for_prices_update_2025_path','/api/v2/account/:account_id/plans_data_for_prices_update_2025');
buildRoute('v2_get_invoice_pdf_path','/api/v2/account/:account_id/invoices/:invoice_id');
buildRoute('v2_remove_subscription_change_path','/api/v2/account/:account_id/remove_subscription_change');
buildRoute('v2_reactivate_subscription_path','/api/v2/account/:account_id/reactivate_subscription');
buildRoute('v2_get_hidden_coupons_path','/api/v2/coupon/hidden_recurly_coupon');
buildRoute('v2_is_eligible_for_coupon_path','/api/v2/coupon/:account_id/is_eligible_for_coupon');
buildRoute('v2_can_change_plan_path','/api/v2/account/:account_id/can_change_to_pricing_plan');
buildRoute('v2_get_pricing_plan_for_account_path','/api/v2/account/:account_id/pricing_plan');
buildRoute('subscriptions_status_update_notification_path','/api/v2/subscription/status_update_notification');
buildRoute('v2_zapier_polling_path','/api/v2/hooks/zapier/ping');
buildRoute('v2_zapier_action_new_inquiry_fields_path','/api/v2/hooks/zapier/action/new_inquiry');
buildRoute('v2_zapier_action_new_inquiry_path','/api/v2/hooks/zapier/action/new_inquiry');
buildRoute('v2_zapier_action_new_contact_path','/api/v2/hooks/zapier/action/new_contact');
buildRoute('v2_zapier_trigger_subscribe_path','/api/v2/hooks/zapier/subscribe');
buildRoute('v2_zapier_trigger_unsubscribe_path','/api/v2/hooks/zapier/unsubscribe');
buildRoute('v2_zapier_trigger_sample_path','/api/v2/hooks/zapier/trigger/:operation/sample');
buildRoute('v2_calendly_public_link_path','/api/v2/users/:id/integrations/calendly/public_link');
buildRoute('v2_calendly_webhook_path','/api/v2/hooks/calendly');
buildRoute('v2_set_calendly_auth_code_path','/api/v2/users/:id/set_calendly_auth_code');
buildRoute('v2_calendly_auth_url_path','/api/v2/users/:id/calendly_auth_url');
buildRoute('v2_disconnect_calendly_path','/api/v2/users/:id/calendly');
buildRoute('v2_integrations_get_auth_url_path','/api/v2/integrations/auth_url');
buildRoute('v2_integrations_disconnect_path','/api/v2/integrations/disconnect');
buildRoute('v2_pictime_integration_status_path','/api/v2/companies/:id/integrations/pictime');
buildRoute('v2_set_pictime_integration_path','/api/v2/companies/:id/integrations/pictime');
buildRoute('v2_pictime_revoke_integration_path','/api/v2/companies/:id/integrations/pictime');
buildRoute('v2_get_event_pictime_projects_path','/api/v2/events/:id/pictime');
buildRoute('v2_remove_pictime_projects_path','/api/v2/events/:id/pictime/:pictime_project_id');
buildRoute('v2_update_pictime_projects_path','/api/v2/events/:id/pictime/:pictime_project_id/display_to_client');
buildRoute('pictime_status_update_notification_path','/api/v2/integrations/pictime/status_update_notification');
buildRoute('v2_get_pictime_coupon_eligibility_path','/api/v2/account/:id/pictime/coupon_eligibility');
buildRoute('v2_get_pictime_unique_perk_path','/api/v2/account/:id/pictime/perk');
buildRoute('v2_toggle_pictime_config_path','/api/v2/companies/:id/integrations/pictime/config');
buildRoute('v2_get_pictime_galleries_list_path','/api/v2/companies/:id/integrations/pictime/galleries');
buildRoute('v2_get_pictime_account_data_path','/api/v2/companies/:id/integrations/pictime/account');
buildRoute('v2_get_pictime_projects_templates_path','/api/v2/companies/:id/integrations/pictime/projects_templates');
buildRoute('v2_assign_existing_gallery_to_event_path','/api/v2/events/:id/pictime/assign/:pictime_project_id');
buildRoute('v2_sync_pictime_gallery_info_path','/api/v2/events/:id/pictime/sync/:pictime_project_id');
buildRoute('v2_create_pictime_gallery_path','/api/v2/events/:id/pictime/create_gallery');
buildRoute('v2_remove_pictime_activity_item_path','/api/v2/events/:id/pictime/:pictime_project_id/remove_activity_item');
buildRoute('pictime_auth_url_path','/api/v2/integrations/pictime/auth_url');
buildRoute('v2_set_prismm_integration_path','/api/v2/companies/:id/integrations/prismm');
buildRoute('v2_prismm_revoke_integration_path','/api/v2/companies/:id/integrations/prismm');
buildRoute('v2_prismm_occasions_path','/api/v2/companies/:id/integrations/prismm/occasions');
buildRoute('v2_assign_prismm_occasion_path','/api/v2/events/:id/prismm/occasion');
buildRoute('v2_event_prismm_occasion_path','/api/v2/events/:id/prismm/occasion');
buildRoute('v2_remove_event_prismm_occasion_path','/api/v2/events/:id/prismm/occasion/:occasion_id');
buildRoute('v2_prismm_occasion_info_path','/api/v2/companies/:id/integrations/prismm/occasion/:occasion_id');
buildRoute('v2_toggle_prismm_config_path','/api/v2/companies/:id/integrations/prismm/config');
buildRoute('v2_toggle_occasion_share_path','/api/v2/events/:id/prismm/occasion/:occasion_id/share');
buildRoute('v2_create_obot_project_path','/api/v2/obot/project');
buildRoute('v2_do_obot_action_path','/api/v2/obot/:workspace_id/action');
buildRoute('v2_users_create_external_path','/api/v2/users/external/:origin');
buildRoute('v2_create_external_proposal_path','/api/v2/proposals/external/:origin');
buildRoute('v2_quickbooks_get_auth_url_path','/api/v2/quickbooks/auth_url');
buildRoute('v2_quickbooks_set_auth_code_path','/api/v2/quickbooks/auth_code');
buildRoute('v2_destroy_quickbooks_path','/api/v2/companies/:company_id/quickbooks');
buildRoute('inbound_twilio_message_path','/api/v2/twilio_inbound_sms');
buildRoute('twilio_status_callback_path','/api/v2/twilio_status_callback');
buildRoute('twilio_click_tracking_callback_path','/api/v2/twilio_click_tracking_callback');
buildRoute('v2_generate_lead_invoice_path','/api/v2/lead_invoice_generator');
buildRoute('v2_generate_lead_free_invoice_path','/api/v2/lead_free_invoice');
buildRoute('v2_generate_lead_email_signature_path','/api/v2/lead_email_signature_generator');
buildRoute('v2_generate_lead_mission_statement_path','/api/v2/lead_mission_statement_generator');
buildRoute('v2_generate_lead_tax_calculator_path','/api/v2/tools/tax_calculator');
buildRoute('v2_project_client_feedback_fetch_path','/api/v2/feedback/:id');
buildRoute('v2_project_client_feedback_update_path','/api/v2/feedback/:id');
buildRoute('v2_capital_get_status_path','/api/v2/capital/user/:id');
buildRoute('v2_capital_get_loans_by_status_path','/api/v2/capital/user/:id/loans_by_status/:status');
buildRoute('v2_capital_initial_vendor_approval_path','/api/v2/capital/user/:id/offer/:offer_id/initial_vendor_approval');
buildRoute('v2_capital_submit_capital_offer_form_path','/api/v2/capital/user/:id/offer/:offer_id/submit_capital_offer_form');
buildRoute('v2_capital_vendor_select_amount_path','/api/v2/capital/user/:id/offer/:offer_id/vendor_select_amount');
buildRoute('v2_capital_vendor_reject_path','/api/v2/capital/user/:id/offer/:offer_id/vendor_reject');
buildRoute('v2_capital_vendor_sign_path','/api/v2/capital/user/:id/offer/:offer_id/vendor_sign');
buildRoute('v2_get_advances_path','/api/v2/capital/user/:id/advances');
buildRoute('v2_plans_for_money_path','/api/v2/capital/user/:id/offer/:offer_id/plans_for_money');
buildRoute('v2_prepay_loan_path','/api/v2/capital/user/:id/offer/:offer_id/prepay_loan');
buildRoute('v2_capital_download_contract_path','/api/v2/capital/user/:id/offer/:offer_id/download_advance_contract');
buildRoute('v2_partner_configuration_path','/api/v2/capital/user/:id/partner/configuration');
buildRoute('v2_workspace_file_update_enable_client_notes_path','/api/v2/workspace_files/:id/client_notes');
buildRoute('v2_workspace_file_create_file_section_path','/api/v2/workspace_files/:id/sections');
buildRoute('v2_workspace_file_update_file_section_path','/api/v2/workspace_files/:id/sections/:section_id');
buildRoute('v2_workspace_file_delete_file_section_path','/api/v2/workspace_files/:id/sections/:section_id');
buildRoute('v2_workspace_file_update_file_sections_order_path','/api/v2/workspace_files/:id/order');
buildRoute('v2_workspace_file_create_file_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks');
buildRoute('v2_workspace_file_update_file_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id');
buildRoute('v2_workspace_file_delete_file_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id');
buildRoute('v2_workspace_file_update_file_section_blocks_order_path','/api/v2/workspace_files/:id/sections/:section_id/order');
buildRoute('v2_workspace_file_update_file_component_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/component');
buildRoute('v2_workspace_file_delete_file_component_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/component');
buildRoute('v2_workspace_file_update_style_path','/api/v2/workspace_files/:id/style');
buildRoute('v2_workspace_file_add_package_to_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/packages');
buildRoute('v2_workspace_file_update_package_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_workspace_file_delete_package_from_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_workspace_file_update_package_services_order_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/packages/:package_id/order');
buildRoute('v2_workspace_file_update_package_quantity_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/packages/:package_id/quantity');
buildRoute('v2_workspace_file_add_service_to_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/services');
buildRoute('v2_workspace_file_update_service_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_workspace_file_delete_service_from_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_workspace_file_move_service_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/services/:service_id/move');
buildRoute('v2_workspace_file_update_service_quantity_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/services/:service_id/quantity');
buildRoute('v2_workspace_file_update_packages_and_services_order_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/proposal/order');
buildRoute('v2_workspace_file_update_proposal_selection_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/proposal/select');
buildRoute('v2_workspace_file_update_proposal_on_block_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/proposal');
buildRoute('v2_workspace_file_update_file_answer_question_path','/api/v2/workspace_files/:id/sections/:section_id/blocks/:block_id/component/answer');
buildRoute('v2_workspace_file_set_file_by_template_path','/api/v2/workspace_files/:id/templates/:template_id');
buildRoute('v2_company_get_template_path','/api/v2/companies/:id/templates/:template_id');
buildRoute('v2_company_template_save_path','/api/v2/companies/:id/templates');
buildRoute('v2_company_template_update_path','/api/v2/companies/:id/templates/:template_id');
buildRoute('v2_company_template_delete_path','/api/v2/companies/:id/templates/:template_id');
buildRoute('v2_company_template_create_template_section_path','/api/v2/companies/:id/templates/:template_id/sections');
buildRoute('v2_company_template_update_template_section_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id');
buildRoute('v2_company_template_delete_template_section_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id');
buildRoute('v2_company_template_update_template_sections_order_path','/api/v2/companies/:id/templates/:template_id/order');
buildRoute('v2_company_template_create_template_block_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks');
buildRoute('v2_company_template_update_template_block_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id');
buildRoute('v2_company_template_delete_template_block_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id');
buildRoute('v2_company_template_update_template_section_blocks_order_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/order');
buildRoute('v2_company_template_update_template_component_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/component');
buildRoute('v2_company_template_delete_template_component_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/component');
buildRoute('v2_company_template_add_package_to_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/packages');
buildRoute('v2_company_template_update_package_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_company_template_delete_package_from_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_company_template_update_package_services_order_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/packages/:package_id/order');
buildRoute('v2_company_template_add_service_to_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/services');
buildRoute('v2_company_template_update_service_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_company_template_delete_service_from_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_company_template_move_service_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/services/:service_id/move');
buildRoute('v2_company_template_update_proposal_packages_and_services_order_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/proposal/order');
buildRoute('v2_company_template_update_proposal_on_template_path','/api/v2/companies/:id/templates/:template_id/sections/:section_id/blocks/:block_id/proposal');
buildRoute('v2_migrate_company_contact_forms_to_dynamic_path','/api/v2/companies/:id/templates/contact_forms/migrate/dynamic');
buildRoute('v2_contact_form_create_block_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks');
buildRoute('v2_contact_form_update_block_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id');
buildRoute('v2_contact_form_delete_block_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id');
buildRoute('v2_contact_form_update_section_blocks_order_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/order');
buildRoute('v2_contact_form_update_component_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/component');
buildRoute('v2_contact_form_update_style_path','/api/v2/contact_forms/:contact_form_id/style');
buildRoute('v2_contact_form_update_settings_path','/api/v2/contact_forms/:contact_form_id/settings');
buildRoute('v2_contact_form_update_inquiry_members_path','/api/v2/contact_forms/:contact_form_id/inquiry_members');
buildRoute('v2_contact_form_reset_changes_path','/api/v2/contact_forms/:contact_form_id/draft');
buildRoute('v2_contact_form_set_active_status_path','/api/v2/contact_forms/:contact_form_id/settings/active');
buildRoute('v2_contact_form_add_package_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/packages');
buildRoute('v2_contact_form_update_package_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_contact_form_delete_package_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/packages/:package_id');
buildRoute('v2_contact_form_update_package_services_order_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/packages/:package_id/order');
buildRoute('v2_contact_form_add_service_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/services');
buildRoute('v2_contact_form_update_service_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_contact_form_delete_service_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/services/:service_id');
buildRoute('v2_contact_form_move_service_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/services/:service_id/move');
buildRoute('v2_contact_form_update_proposal_packages_and_services_order_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/proposal/order');
buildRoute('v2_contact_form_update_proposal_path','/api/v2/contact_forms/:contact_form_id/sections/:section_id/blocks/:block_id/proposal');
buildRoute('v2_create_project_from_extension_path','/api/v2/ext/events');
buildRoute('v2_get_workspace_for_extension_path','/api/v2/ext/workspaces/:id');
buildRoute('v2_get_workspace_by_gmail_thread_id_path','/api/v2/ext/workspaces/by_gmail_thread/:thread_id');
buildRoute('v2_get_workspaces_data_for_extension_path','/api/v2/ext/workspaces');
buildRoute('v2_get_curr_user_for_extension_path','/api/v2/ext/user');
buildRoute('v2_create_flow_template_path','/api/v2/flow');
buildRoute('v2_merge_flow_revisions_path','/api/v2/flow/:flow_id/merge_revisions');
buildRoute('v2_create_flow_instance_path','/api/v2/flow/instance');
buildRoute('v2_create_flow_from_legacy_file_path','/api/v2/flow/instance_from_legacy');
buildRoute('v2_create_flow_from_service_selection_path','/api/v2/flow/instance_from_service_selection');
buildRoute('v2_create_invoice_from_service_templates_path','/api/v2/flow/invoice_from_service_template');
buildRoute('v2_create_template_from_block_path','/api/v2/flow/:flow_id/block/template');
buildRoute('v2_share_flow_template_path','/api/v2/flow/:flow_id/share_flow_template');
buildRoute('v2_publish_flow_template_path','/api/v2/flow/:flow_id/template/publish');
buildRoute('v2_get_public_template_flow_view_path','/api/v2/flow/:flow_id/template/view');
buildRoute('v2_get_public_template_url_path','/api/v2/flow/:flow_id/template/public_url');
buildRoute('v2_get_thumbnail_signed_url_path','/api/v2/flow/:flow_id/template/thumbnail_url');
buildRoute('v2_get_active_public_flow_path','/api/v2/public_flow/:flow_id/active');
buildRoute('v2_update_public_template_config_path','/api/v2/flow/:flow_id/template/settings');
buildRoute('v2_update_priced_template_selled_lead_form_path','/api/v2/flow/:flow_id/template/selled_lead_form');
buildRoute('v2_update_block_template_path','/api/v2/flow/:flow_id/block/template');
buildRoute('v2_submit_flow_path','/api/v2/flow/:flow_id/submit/:page_id');
buildRoute('v2_submit_view_only_flow_path','/api/v2/flow/:flow_id/submit_view_only/:page_id');
buildRoute('v2_reset_view_only_link_path','/api/v2/flow/:flow_id/reset_view_only_link');
buildRoute('v2_client_sign_contract_path','/api/v2/flow/:flow_id/sign_contract/:page_id');
buildRoute('v2_client_answered_question_path','/api/v2/flow/:flow_id/answer_question/:page_id');
buildRoute('v2_client_select_slot_path','/api/v2/flow/:flow_id/select_slot/:page_id');
buildRoute('v2_client_cancel_session_path','/api/v2/flow/:flow_id/cancel_session/:page_id');
buildRoute('v2_select_service_path','/api/v2/flow/:flow_id/select_service/:page_id');
buildRoute('v2_validate_submit_flow_path','/api/v2/flow/:flow_id/validate');
buildRoute('v2_check_flow_user_auth_path','/api/v2/flow/:flow_id/auth');
buildRoute('v2_check_flow_user_auth_new_path','/api/v2/flow/:flow_id/auth');
buildRoute('v2_get_flow_path','/api/v2/flow/:flow_id');
buildRoute('get_flow_by_revision_path','/api/v2/flow/:flow_id/revision/:revision_id');
buildRoute('get_revisions_for_flow_path','/api/v2/flow/:flow_id/revisions');
buildRoute('v2_preview_flow_path','/api/v2/flow/:flow_id/preview');
buildRoute('v2_get_shared_template_path','/api/v2/flows/shared_templates/preview');
buildRoute('v2_save_shared_template_path','/api/v2/flows/shared_templates/save');
buildRoute('v2_import_public_template_path','/api/v2/flows/public_template/:flow_id/import');
buildRoute('v2_redeem_priced_template_path','/api/v2/flows/public_template/:redeem_code/redeem');
buildRoute('v2_get_minimal_flow_path','/api/v2/flow/:flow_id/minimal');
buildRoute('v2_get_active_flow_path','/api/v2/flow/:flow_id/active');
buildRoute('v2_get_flow_view_only_path','/api/v2/flow/:flow_id/view');
buildRoute('v2_get_flow_contract_fields_path','/api/v2/flow/:flow_id/contract_fields');
buildRoute('v2_pre_publish_flow_path','/api/v2/flow/:flow_id/pre_publish');
buildRoute('v2_get_last_action_number_path','/api/v2/flow/:flow_id/last_action_number');
buildRoute('v2_update_flow_path','/api/v2/flow/:flow_id');
buildRoute('v2_apply_actions_on_flow_path','/api/v2/flow/:flow_id/apply_actions');
buildRoute('v2_update_configuration_path','/api/v2/flow/:flow_id/configuration');
buildRoute('v2_sign_without_submission_path','/api/v2/flow/:flow_id/sign');
buildRoute('v2_approve_flow_changes_path','/api/v2/flow/:flow_id/:block_id/approve');
buildRoute('v2_recover_flow_path','/api/v2/flow/:flow_id/recover');
buildRoute('v2_remove_schedule_send_path','/api/v2/flow/:flow_id/remove_schedule_send');
buildRoute('v2_flow_cancel_session_path','/api/v2/flow/:flow_id/scheduling/:variable_id/cancel');
buildRoute('v2_flow_plaid_create_link_token_path','/api/v2/flow/:flow_id/users/:id/plaid/create_link_token');
buildRoute('v2_flow_create_chargeable_bank_account_path','/api/v2/flow/:flow_id/users/:id/chargeable_bank_account');
buildRoute('v2_flow_plaid_exchange_public_token_path','/api/v2/flow/:flow_id/users/:id/plaid/exchange_public_token');
buildRoute('v2_flow_report_plaid_event_path','/api/v2/flow/:flow_id/users/:id/plaid/event');
buildRoute('v2_flow_validate_user_credit_card_expiration_date_path','/api/v2/flow/:flow_id/users/validations/credit_card/date/:payment_method_id');
buildRoute('v2_flow_create_payment_method_path','/api/v2/flow/:flow_id/users/:id/payment_methods');
buildRoute('v2_flow_payment_pay_path','/api/v2/flow/:flow_id/payments/:payment_id/payment_methods/:payment_method_id');
buildRoute('v2_flow_payment_waive_late_fee_path','/api/v2/flow/:flow_id/payments/:payment_id/waive_late_fee');
buildRoute('v2_flow_mark_payment_as_paid_path','/api/v2/flow/:flow_id/payments/:payment_id/paid');
buildRoute('v2_flow_mark_payment_as_refunded_path','/api/v2/flow/:flow_id/payments/:payment_id/mark_refunded');
buildRoute('v2_flow_request_milestone_payment_path','/api/v2/flow/:flow_id/payments/:payment_id/request_payment');
buildRoute('v2_flow_undo_mark_payment_as_paid_path','/api/v2/flow/:flow_id/payments/:payment_id/undo_paid');
buildRoute('v2_flow_send_payment_reminder_path','/api/v2/flow/:flow_id/payments/:payment_id/reminder');
buildRoute('v2_flow_pre_charge_validate_path','/api/v2/flow/:flow_id/payments/:payment_id/payment_methods/:payment_method_id/validate');
buildRoute('v2_flow_stop_recurring_path','/api/v2/flow/:flow_id/payments/stop');
buildRoute('v2_flow_manual_quickbooks_sync_path','/api/v2/flow/:flow_id/quickbooks');
buildRoute('v2_save_flow_in_elastic_path','/api/v2/flow/:flow_id/save_flow_in_elastic');
buildRoute('v2_flow_delete_payment_from_quickbooks_path','/api/v2/flow/:flow_id/payments/:payment_id/quickbooks');
buildRoute('v2_flow_delete_stripe_card_path','/api/v2/flow/:flow_id/users/:user_id/stripe_card/:id');
buildRoute('v2_flow_update_auto_payment_path','/api/v2/flow/:flow_id/update_auto_payment');
buildRoute('v2_mark_flow_as_complete_path','/api/v2/flow/:flow_id/complete');
buildRoute('v2_payment_invoice_path','/api/v2/flow/:flow_id/payment_invoice/:payment_id');
buildRoute('v2_payment_invoice_by_payment_id_path','/api/v2/flow/:flow_id/payment_invoice_by_payment_id/:payment_id');
buildRoute('v2_get_estimated_fee_path','/api/v2/flow/:flow_id/estimated_fee');
buildRoute('v2_client_select_number_of_payments_path','/api/v2/flow/:flow_id/select_number_of_payments');
buildRoute('v2_set_flow_permissions_path','/api/v2/flow/:flow_id/flow_permissions');
buildRoute('v2_get_flow_permissions_path','/api/v2/flow/:flow_id/flow_permissions');
buildRoute('v2_flow_initiate_payment_path','/api/v2/flow/:flow_id/payments/:payment_id');
buildRoute('v2_flow_initiate_ttp_payment_path','/api/v2/m/flow/:flow_id/payments/:payment_id');
buildRoute('v2_flow_mark_payment_as_charged_path','/api/v2/flow/:flow_id/payments/:payment_id/charged');
buildRoute('v2_flow_mark_ttp_payment_as_charged_path','/api/v2/flow/:flow_id/payments/:payment_id/ttp_charged');
buildRoute('v2_get_charge_destination_path','/api/v2/flow/:flow_id/charge_destination_account_id');
buildRoute('v2_list_service_templates_path','/api/v2/company/:company_id/service_templates');
buildRoute('v2_get_service_template_path','/api/v2/company/:company_id/service_template/:service_template_id');
buildRoute('v2_create_service_template_path','/api/v2/company/:company_id/service_template');
buildRoute('v2_duplicate_service_template_path','/api/v2/company/:company_id/service_template/:service_template_id');
buildRoute('v2_update_service_template_path','/api/v2/company/:company_id/service_template/:service_template_id');
buildRoute('v2_delete_service_template_path','/api/v2/company/:company_id/service_template/:service_template_id');
buildRoute('v2_list_ai_service_templates_path','/api/v2/company/:company_id/ai_service_templates');
buildRoute('v2_list_hb_lead_forms_path','/api/v2/public/public_flows');
buildRoute('v2_create_lead_form_path','/api/v2/public/public_flow');
buildRoute('v2_submit_lead_form_path','/api/v2/public/:flow_id/submit');
buildRoute('v2_deactivate_lead_form_path','/api/v2/public/:flow_id/deactivate');
buildRoute('v2_activate_lead_form_path','/api/v2/public/:flow_id/activate');
buildRoute('v2_delete_lead_form_path','/api/v2/public/:flow_id');
buildRoute('v2_get_and_update_active_public_flow_path','/api/v2/public/:flow_id/active');
buildRoute('v2_get_post_submit_data_path','/api/v2/public/submit_results');
buildRoute('v2_get_lead_form_cx_experience_path','/api/v2/public/:company_id/experience');
buildRoute('v2_delete_all_historic_slugs_path','/api/v2/public/:flow_id/delete_slugs');
buildRoute('v2_embed_path','/api/v2/public/:flow_id/embed');
buildRoute('v2_migrate_file_path','/api/v2/flow/migrate');
buildRoute('v2_otam_migration_path','/api/v2/flow/otam_migration');
buildRoute('v2_delete_flow_path','/api/v2/flow/:flow_id');
buildRoute('v2_discard_flow_draft_path','/api/v2/flow/:flow_id/draft');
buildRoute('v2_get_connected_workflows_path','/api/v2/flow/:flow_id/workflows');
buildRoute('v2_get_linked_workflow_path','/api/v2/flow/:flow_id/linked_workflows');
buildRoute('v2_preview_payments_path','/api/v2/flow/:flow_id/preview_payments');
buildRoute('v2_get_most_ranked_templates_path','/api/v2/flows/template/most_ranked');
buildRoute('v2_get_flow_smart_fields_path','/api/v2/flow/:flow_id/smart_fields');
buildRoute('v2_flow_allow_charged_instant_payout_path','/api/v2/flows/:flow_id/payments/:payment_id/allow_charged_instant_payout');
buildRoute('v2_flow_get_payment_path','/api/v2/flows/:flow_id/payments/:invoice_id/');
buildRoute('v2_flow_toggle_payment_as_instant_path','/api/v2/flows/:flow_id/payments/:payment_id/instant_payout');
buildRoute('v2_debug_get_flow_by_id_path','/api/v2/tools/flow/:flow_id');
buildRoute('v2_debug_fix_flow_by_id_path','/api/v2/tools/flow/:flow_id/issues');
buildRoute('v2_debug_get_flow_tree_by_id_path','/api/v2/tools/flow/:flow_id/tree');
buildRoute('v2_debug_get_flow_revision_by_id_path','/api/v2/tools/flow/revision/:revision_id');
buildRoute('v2_debug_get_flow_revision_tree_by_id_path','/api/v2/tools/flow/revision/:revision_id/tree');
buildRoute('v2_debug_get_flow_content_by_id_path','/api/v2/tools/flow/content/:flow_content_id');
buildRoute('v2_debug_get_flow_content_tree_by_id_path','/api/v2/tools/flow/content/:flow_content_id/tree');
buildRoute('v2_generate_content_url_path','/api/v2/content/url');
buildRoute('v2_send_verification_code_email_path','/api/v2/users/verification_code/email');
buildRoute('v2_submit_verification_code_path','/api/v2/users/verification_code/submit');
buildRoute('v2_list_lead_suggestions_path','/api/v2/lead_suggestions');
buildRoute('v2_list_lead_suggestions_count_path','/api/v2/lead_suggestions/count');
buildRoute('v2_delete_lead_suggestion_path','/api/v2/lead_suggestions/:id');
buildRoute('v2_add_lead_suggestion_to_pipeline_path','/api/v2/lead_suggestions/:id/add_to_pipeline');
buildRoute('v2_add_lead_suggestion_reaction_path','/api/v2/lead_suggestions/:id/reaction');
buildRoute('v2_hot_leads_prediction_path','/api/v2/ds/hot_leads/new_prediction');
buildRoute('v2_auto_suggested_response_path','/api/v2/ds/inquiry/auto_suggested_response');
buildRoute('v2_auto_suggested_edit_path','/api/v2/ds/inquiry/auto_suggested_edit');
buildRoute('v2_smarf_template_information_path','/api/v2/ds/smarf_template_information');
buildRoute('v2_web_and_social_information_path','/api/v2/ds/instant_branding/web_and_social_information');
buildRoute('v2_create_service_templates_ds_path','/api/v2/ds/service');
buildRoute('v2_project_recaps_summary_done_path','/api/v2/ds/project_recaps');
buildRoute('v2_meeting_assistant_summary_done_path','/api/v2/ds/meeting_assistant');
buildRoute('v2_transcript_done_path','/api/v2/ds/transcript');
buildRoute('v2_gate_open_migration_path','/api/v2/ds/gate_open_migration');
buildRoute('v2_embed_pdf_services_path','/api/v2/ds/embed_pdf_services');
buildRoute('v2_client_enrichment_path','/api/v2/ds/client_enrichment');
buildRoute('v2_spam_check_response_path','/api/v2/ds/spam_check_response');
buildRoute('v2_lead_suggestion_path','/api/v2/ds/lead_suggestion');
buildRoute('v2_finish_lead_suggestions_import_path','/api/v2/ds/finish_lead_suggestions_import');
buildRoute('v2_get_inquiry_by_workspace_path','/api/v2/inquiry_by_workspace/:workspace_id');
buildRoute('v2_fetch_inquiry_by_workspace_path','/api/v2/fetch_inquiry_by_workspace/:workspace_id');
buildRoute('v2_inquiry_auto_suggestion_reaction_path','/api/v2/inquiry_auto_suggestion_reaction/:suggestion_id/:reaction');
buildRoute('v2_inquiry_auto_suggestion_reaction_text_path','/api/v2/inquiry_auto_suggestion_reaction_text');
buildRoute('v2_is_auto_suggestion_enabled_path','/api/v2/inquiry_auto_suggestion_enabled/');
buildRoute('v2_auto_suggestion_opting_path','/api/v2/inquiry_auto_suggestion_opting/:opt_in');
buildRoute('v2_get_hot_leads_state_path','/api/v2/inquiry_get_hot_leads_state/');
buildRoute('v2_update_hot_leads_opt_in_path','/api/v2/update_hot_leads_opt_in/:opt_in');
buildRoute('v2_auto_suggestion_text_used_path','/api/v2/inquiry_auto_suggestion_text_used/');
buildRoute('v2_auto_suggestion_edits_path','/api/v2/auto_suggestion_edits');
buildRoute('v2_get_inquiry_follow_up_state_path','/api/v2/inquiry_follow_up/');
buildRoute('v2_update_inquiry_follow_up_path','/api/v2/update_inquiry_follow_up');
buildRoute('v2_suggestion_by_context_id_path','/api/v2/suggestion/:subject_id');
buildRoute('v2_suggestion_by_context_id_with_eligibility_path','/api/v2/suggestion/eligibility');
buildRoute('v2_suggestion_reaction_path','/api/v2/suggestion/reaction');
buildRoute('v2_suggestion_reaction_text_path','/api/v2/suggestion/reaction_text');
buildRoute('v2_suggestion_toggle_opt_in_path','/api/v2/suggestion/toggle');
buildRoute('v2_suggestion_is_enabled_path','/api/v2/suggestion/is_enabled/:context_name');
buildRoute('v2_suggestion_create_custom_prompts_model_path','/api/v2/suggestion/create_custom_prompts_model');
buildRoute('v2_suggestion_text_used_path','/api/v2/suggestion/text_used');
buildRoute('v2_meeting_transcript_path','/api/v2/transcript/meeting_transcript');
buildRoute('v2_ai_notes_reaction_path','/api/v2/transcript/notes/:note_id/reaction');
buildRoute('v2_suggestion_toggle_composer_ai_injection_opt_in_path','/api/v2/suggestion/toggle_composer_ai_injection');
buildRoute('v2_ai_project_recaps_notes_reaction_path','/api/v2/project_recaps/notes/:note_id/reaction');
buildRoute('v2_get_project_recap_pre_meeting_settings_path','/api/v2/project_recaps/pre_meeting_settings');
buildRoute('v2_update_project_recap_pre_meeting_settings_path','/api/v2/project_recaps/pre_meeting_settings');
buildRoute('v2_get_project_recap_pre_project_settings_path','/api/v2/project_recaps/pre_project_settings');
buildRoute('v2_update_project_recap_pre_project_settings_path','/api/v2/project_recaps/pre_project_settings');
buildRoute('v2_get_meeting_transcript_settings_path','/api/v2/transcript/settings');
buildRoute('v2_update_meeting_transcript_settings_path','/api/v2/transcript/settings');
buildRoute('v2_s3_upload_audio_url_path','/api/v2/transcript/s3_upload_audio_url');
buildRoute('v2_s3_upload_audio_done_path','/api/v2/transcript/s3_upload_audio_done');
buildRoute('v2_set_by_login_id_path','/api/v2/own_id/setOwnIDDataByLoginId');
buildRoute('v2_get_by_login_id_path','/api/v2/own_id/getOwnIDDataByLoginId');
buildRoute('v2_get_session_by_login_id_path','/api/v2/own_id/getSessionByLoginId');
buildRoute('v2_get_process_map_path','/api/v2/process_maps/:id');
buildRoute('v2_get_company_process_maps_path','/api/v2/process_maps/company/:company_id');
buildRoute('v2_get_process_map_id_for_quiz_path','/api/v2/process_maps/company/:company_id/quiz/:token');
buildRoute('v2_update_process_map_thumbnail_path','/api/v2/process_maps/:id/update_thumbnail');
buildRoute('v2_rename_process_map_path','/api/v2/process_maps/:id/rename');
buildRoute('v2_delete_process_map_path','/api/v2/process_maps/:id');
buildRoute('v2_intellum_o_auth_authorize_path','/api/v2/intellum/oauth/authorize');
buildRoute('v2_intellum_o_auth_authorize_code_path','/api/v2/intellum/oauth/authorize_code');
buildRoute('v2_intellum_o_auth_authorize_token_path','/api/v2/intellum/oauth/token');
buildRoute('v2_intellum_o_auth_user_details_path','/api/v2/intellum/oauth/user_details');
