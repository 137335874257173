(function () {
    "use strict";

    // @ngInject
    function WorkspaceFilesControllerCtor($scope, $stateParams, $injector, _, Enums, $timeout, JsSlicklightboxService,ToastService,
                                          WorkspacesManager, WorkspaceFilesManager, UsersManager, WebsocketHelperService, PopupMessageService,
                                          WorkspaceFileService, AnalyticsService, DeviceService, OnboardingService, RepositoryService,
                                          FeaturesService, FlowsBetaUserTypeService, FlowsManager, StatsigService,
                                          UIUtils, ngToast, $sce, $translate, CompaniesManager) {

        var self = this;

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspaceFilesController';
        AnalyticsService.trackPageView(this, 'workspace files');

        this._ = _;
        this.WorkspacesManager = WorkspacesManager;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.OnboardingService = OnboardingService;
        this.AnalyticsService = AnalyticsService;
        this.DeviceService = DeviceService;
        this.WebsocketHelperService = WebsocketHelperService;
        this.$timeout = $timeout;
        this.PopupMessageService = PopupMessageService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.FeaturesService = FeaturesService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.UIUtils = UIUtils;
        this.ngToast = ngToast;
        this.ToastService = ToastService;
        this.$sce = $sce;
        this.lightboxContainer = "#how-it-looks-files";
        this.$translate = $translate;
        this.deleteTooltipText = this.$translate.instant('WORKSPACE.FILES._DISABLED_DELETE_ATTACHMENT_TOOLTIP_');
            
        // save event
        var eventId = $stateParams.event_id;
        this.eventId = eventId;
        var workspaceId = $stateParams.workspace_id; // new
        this.workspaceId = workspaceId;
        RepositoryService.persistentPut('event:' + eventId, {tabToOpen: Enums.workspaceTabTypes.files, latestWsId: workspaceId});

        this.loading = true;
        this.isInitialSuccess = true;
        this.fileActiveStateChanged = false;
        this.isTouch = DeviceService.checkIfTouchScreen();
        this.fileNotificationHash = {};
        this.user = UsersManager.getCurrUser();
        this.company = CompaniesManager.getCurrCompany();
        this.hasPictimeIntegration = this.company.has_pictime_integration;
        this.isVendor = this.user.isVendor();
        this.isOnboardingActive = OnboardingService.isOnboardingActive();
        this.attachmentsLimit = 14;
        this.maxAttachments = 100;
        this.toggleCreateFileOptions = {scroll: true, delay: 200};
        this.JsSlicklightboxService = JsSlicklightboxService;
        this.galleryInitialized = false;
        this.howItLooksImages = [
            "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1524037769/empty_states/how-it-works/activity_image_01.png",
            "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1524046387/empty_states/how-it-works/activity_image_02.png",
            "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1523892464/empty_states/how-it-works/activity_image_03.png",
            "//res.cloudinary.com/honeybook/image/upload/dpr_auto,q_auto/v1523892466/empty_states/how-it-works/activity_image_04.png"
        ];

        this.emptyStatePrimaryCtaAnalyticsData = {
            eventName : this.AnalyticsService.analytics_events.create_new,
            data: {
                source: 'workspace_files_empty_state',
                page: 'workspace_files',
                unique: 'empty_state_primary_cta'
            }
        };
        this.emptyStateTextInlineCtaAnalyticsData = {
            eventName : this.AnalyticsService.analytics_events.action,
            data: {
                page: 'workspace_files',
                unique: 'empty_state_secondary_cta'
            }
        };

        this.workspace = WorkspacesManager.getWorkspace(workspaceId);
        this.register(this.workspace, 'success', this.gotWorkspace.bind(this));

        if (!this.workspace.isModelUpdating()) {
            this.gotWorkspace();
        }

        this.workspaceFiles = this.workspace.workspace_files;
        this.initFlows();

        var userSentAFile = UsersManager.getCurrUser().last_file_sent_time != null;
        this.emptyStateHeader = userSentAFile ? 'WORKSPACE.FILES._EMPTY_STATE_HEADER_' : 'WORKSPACE.FILES._EMPTY_STATE_HEADER_TRIALER_';
        this.emptyStateText = userSentAFile ? 'WORKSPACE.FILES._EMPTY_STATE_TEXT_' : 'WORKSPACE.FILES._EMPTY_STATE_TEXT_TRIALER_';

        this.deleteFlow = function deleteFlow(flowId) {
            this.flows = FlowsManager.afterDeleteFlow([].concat(this.flows), flowId);
        }.bind(this);

        this.renameFlow = function renameFlow(flowId, newTitle) {
            const flow = this.flows.find(function(_flow) { return _flow._id === flowId; });

            if (!flow) {
                throw new Error('Flow: ' + flowId + ' does not exist, cannot rename');
            }

            flow.title = newTitle;
            this.flows = [].concat(this.flows); // must mutate flows in order for the $watch to trigger a re-render in the react component
        }.bind(this);

        this.markFlowAsCompleted = function markFlowAsCompleted(flowId) {
            const flow = this.flows.find(function(_flow) { return _flow._id === flowId; });

            if (!flow) {
                this.AnalyticsService.trackError(this, 'Flow: does not exist, cannot mark as completed', {flow: this.flow});
                throw new Error('Flow: ' + flowId + ' does not exist, cannot mark as completed');
            }

            flow.status = "completed";

            this.ToastService.showSuccess({
                contentTranslation: this.$translate.instant('WORKSPACE.FILES._MARK_AS_COMPLETED_TOAST_'),
                dismissOnTimeout: true,
                dismissButton: true,
                timeout: 3000
            });
            this.flows = [].concat(this.flows); // must mutate flows in order for the $watch to trigger a re-render in the react component

        }.bind(this);

        this.removeNotificationIfNeeded = function removeNotificationIfNeeded() {
            var filesToMarkAsNotificationSeen = [];

            if (this.workspace.workspace_notifications && this.workspace.workspace_notifications.length > 0) {
                this.workspace.workspace_notifications.forEach(function (notification) {
                    var workspaceFile = this._.findWhere(this.workspaceFiles, {_id: notification.file_id});
                    if (workspaceFile && workspaceFile.isOwnedByCurrUser() && notification.seen === false) {
                        //then being in this controller means that it IS seen. mark it as so.
                        filesToMarkAsNotificationSeen.push(workspaceFile._id);
                    }
                }.bind(this));

                if (filesToMarkAsNotificationSeen.length > 0) {
                    this.WorkspacesManager.updateFileNotificationsAsSeen(this.workspace, filesToMarkAsNotificationSeen);
                }
            }
        };

        if (angular.isArray(this.workspace.workspace_notifications)) {
            this.fileNotificationHash = {};
            this.workspace.workspace_notifications.forEach(function (notification) {
                this.fileNotificationHash[notification.file_id] = angular.copy(notification);
            }.bind(this));
        }

        this.removeNotificationIfNeeded();

        this.WorkspaceFileService.setCreatingFileListener(this);
        this.imageSource = 'https://s3.amazonaws.com/honeybook_cdn/assets_system/system_web/main_general/alexis-thumb.jpg';

        this.WebsocketHelperService.registerToRoom(this.workspaceId + ".reload_workspace_files", this.refreshWorkspaceFilesWebsocket.bind(this));
        this.WebsocketHelperService.registerToRoom(this.workspaceId + ".attachments_updated", this._getAttachments.bind(this));

        // remove all listeners on destruction to avoid leaks
        $scope.$on('$destroy', function () {
            this.WorkspaceFileService.setCreatingFileListener(self);
            this.WebsocketHelperService.unregisterFromRoom(this.workspaceId + ".reload_workspace_files");
            this.WebsocketHelperService.unregisterFromRoom(this.workspaceId + ".attachments_updated");
        }.bind(this));

        this.filesLimit = 100000000;

        this.workspaceFileTypes = Enums.WorkspaceFileTypes;

        this.cardInfo = {
            proposal: {
                icon: 'icon-hb-proposal',
                title:'Proposal',
                text:'The revolutionary all-in-one: Clients can view your services, sign the contract and pay. From zero to booked in no time!',
                top: '55px',
                top2: '72px'
            },
            brochure: {
                icon: 'icon-hb-brochure',
                title:'Brochure',
                text:'Showcase your packages and prices. A proposal will be automatically created from your client\'s selection, ready for you to send.',
                top: '15px',
                top2: '32px'
            },
            invoice: {
                icon: 'icon-hb-invoice',
                title:'Invoice',
                text:'Need a simple invoice without an contract? Look no further.',
                top: '135px',
                top2: '153px'
            },
            agreement:{
                icon: 'icon-hb-agreement',
                title:'Contract',
                text:'Your easy way to get an contract digitally signed on HoneyBook.',
                top: '174px',
                top2: '113px'
            },
            questionnaire: {
                icon: 'icon-hb-questionnaire',
                title:'Questionnaire',
                text:'Need more client information? Customize your questionnaire to have everything on HoneyBook.',
                top: '95px',
                top2: '113px'
            },
            timeline: {
                icon: 'icon-hb-timeline',
                title:'Timeline',
                text:'Whether for day-of or for the lead up to an event, enter and share all the milestones here for optimal collaboration.',
                top: '215px'
            },

        };

        this.currentCardInfo = this.cardInfo.brochure;

        this.hoverCard =  function hoverCard(type){
            self.$parent.workspaceVm.hoverCardOn = true;
            self.$parent.workspaceVm.currentCardInfo = self.cardInfo[type];
        };

        this.unhoverCard = function unhoverCard(){
            self.$parent.workspaceVm.hoverCardOn = false;
        };

        this.loadingAttachments = true;

        this.deleteAttachment = function deleteAttachment (attachment) {
            this.PopupMessageService.showConfirmPromise(
                this.PopupMessageService.severityTypes.info,
                'WORKSPACE.FILES._CONFIRM_DELETE_',
                'FREQUENT_BUTTONS._DELETE_',
                'FREQUENT_BUTTONS._CANCEL_')
                .then(function() {
                    this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.delete_attachment);
                    this.WorkspacesManager.deleteAttachment(this.workspace, attachment._id, attachment.asset_type);
                }.bind(this));
        }.bind(this);

        this.onDownloadFile = function onDownloadFile () {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.download_attachment);
        }.bind(this);

        var unwatchFiles = $scope.$watchCollection('workspaceFilesVm.workspace.attachments.files', function () {
            this.formatFiles();

            var attachmentFiles = this.workspace.attachments && this.workspace.attachments.files;

            this.thumbnailsLoaderParams = {
                width: 100,
                height: 120,
                style: { marginRight: '7px' },
                count: attachmentFiles ? attachmentFiles.length : 0
            };
        }.bind(this));

        $scope.$on('$destroy', function () {
            unwatchFiles();
        });

        this.formatFiles = function () {
            if (!this.workspace.attachments) {
                return [];
            }

            this.formattedAttachmentFiles = this.workspace.attachments.files.map(function (file) {
                return {
                    _id: file._id,
                    name: file.asset_name || file.name,
                    type: file.file_type,
                    url: file.url,
                    isUploading: !!file.inUpload,
                    isDeleteDisabled: !!file.flow_id
                };
            });
        }.bind(this);

        this.canDeleteAttachment = function canDeleteAttachment(attachment) {

            var self = this;

            if (attachment.creator_id === this.user._id) {
                return true;
            }

            var uploadingUser = this.workspace.members.find(function(member) { return member._id === attachment.creator_id; });

            if (!uploadingUser) {
                return this.creator.isMyTeamMember(this.user) && (this.user.hasCompanyModeratorPermissions() || this.workspace.members.find(function(member) { return member._id === self.user._id; }));
            }

            return this.user.isMyTeamMember(uploadingUser) && this.user.hasCompanyModeratorPermissions();
        }.bind(this);

        this.onAfterDelete = function onDeleteWorkspace(workspaceFileId) {
            this.WorkspaceFilesManager.removeWorkspaceFileFromCache(this.workspace.workspace_files.find(function(workspace_file){
                return workspace_file._id === workspaceFileId;
            }));
            this.workspaceFiles = [].concat(this.workspaceFiles);
        }.bind(this);

        this.onWorkspaceFileUpdated = function onWorkspaceFileUpdated(workspaceFile) {
            this.WorkspaceFilesManager.updateWorkspaceFileExternal(workspaceFile);
            var workspaceFileIndex = this.workspaceFiles.findIndex(function(_workspaceFile) { return _workspaceFile._id === workspaceFile._id; });
            this.workspaceFiles[workspaceFileIndex] = Object.assign({}, this.workspaceFiles[workspaceFileIndex], workspaceFile);
            this.workspaceFiles = [].concat(this.workspaceFiles);
        }.bind(this);

        var isClient = this.user.isClient();
        var isVendorAsClient = this.isVendor &&
            this.user.workspace_user_data &&
            this.user.workspace_user_data.workspace_role === 'host';

        this.version = null;
        if (isClient || isVendorAsClient) {
            this.version = 'angular';
        } else {
            StatsigService.isGateEnabled('ng2react_workspace_files_tab').then(function(enabled){
                this.version = enabled ? 'react' : 'angular';
            }.bind(this));
        }

        this.isReactEmptyStateEnabled = false;
        // ng2react_workspace_activity_tab is on purpose - the react empty state interacts with the react
        // create file button. the create file button is in react when ng2react_workspace_activity_tab is open
        StatsigService.isGateEnabled('ng2react_workspace_activity_tab').then(function(result){
            this.isReactEmptyStateEnabled = result;
        }.bind(this));
    }

    Controllers.WorkspaceFilesController = Class(Controllers.BaseController, {

        constructor: WorkspaceFilesControllerCtor,

        gotWorkspace: function gotWorkspace() {
            this.loading = false;

            if(this.isInitialSuccess) {
                this.isInitialSuccess = false;
                this._getAttachments();
                this.creator = this.workspace.members.find(function(member) { return member._id === this.workspace.creator_id; }.bind(this));
            }

            this.initFlows();

            this.pictimeGalleriesNumber = this.workspace.event.pictime_projects.filter(function (gallery) {
                return gallery.is_displayed_in_files;
            }).length;

            this.isMyTeamMember = this.workspace.company_id === this.user.company._id;
        },

        showScheduleCanceledToast: function showScheduleCanceledToast(flowTitle){
            const toastContent   = '<div class="flow-action-toast-content">\
                                        <i class="icon icon-hb-nx-trash"></i>\
                                        <div class="text-container">\
                                            <div>Scheduled send of "' + this.UIUtils.sanitize(flowTitle) + '" smart file was canceled</div>\
                                        </div>\
                                    </div>';
                this.ngToast.create({
                    content: this.$sce.trustAsHtml(toastContent),
                    className: 'flow-action-toast alert-danger',
                    dismissOnTimeout: true,
                    timeout: 4000,
                    dismissButton: false,
                    compileContent: this.$scope
                });
        },

        updateAfterDelete: function updateAfterDelete(connectedObjId) {
            var flow = this.workspace.flows.find(function (flow) { return flow._id === connectedObjId;});

            if (flow) {
                flow.scheduled_to_be_sent = null;
                this.showScheduleCanceledToast(flow.title);
            }

            this.initFlows();
        },

        initFlows: function initFlows() {
            this.flows = (this.workspace.flows || []).sort(function(a,b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });

            this.flows = [].concat(this.flows);
            this.showFlows = this.flows.length > 0;
        },

        refreshWorkspaceFilesWebsocket: function () {
            this.$timeout(function (){
                this.workspace = this.WorkspacesManager.getWorkspace(this.workspaceId);
            }.bind(this) , 2000);
        },

        goToWorkspaceFile: function goToWorkspaceFile(workspaceFileId) {
            this.goToState(this.AppStates.root_core_workspaceFile, {workspace_file_id: workspaceFileId});
        },

        setCreatingFile: function setCreatingFile(){
            this.filesLimit = this.workspaceFiles.length;
        },

        removeCreatingFile: function removeCreatingFile(){
            this.filesLimit = 100000000;
        },

        workspaceCardEnter: function workspaceCardEnter ($event, entering) {
            var targetElm = $($event.currentTarget);
            var nextElmSibilings = targetElm.nextAll('li');
            var prevElmSibilings = targetElm.prevAll('li');
            var inactive = 'nx-workspace__list-item--inactive';
            var active = 'nx-workspace__list-item--active';

            if (entering) {
                nextElmSibilings.removeClass(inactive).addClass(inactive);
                prevElmSibilings.removeClass(inactive).addClass(inactive);
                targetElm.addClass(active);
            } else {
                nextElmSibilings.removeClass(inactive);
                prevElmSibilings.removeClass(inactive);
                targetElm.removeClass(active);
            }
        },

        toggleAttachmentsLimit: function toggleAttachmentsLimit(type, minimized) {
            this.attachmentsToShow[type].minimized = minimized;
            this.attachmentsToShow[type].limit = minimized ? this.attachmentsToShow[type].min : this.attachmentsToShow[type].max;
        },

        showToggleAttachmentLimit: function showToggleAttachmentLimit(type, minimized) {
            return !this.loadingAttachments &&
                    (type === 'files' || (type === 'images' && this.imagesReady)) &&
                    this.workspace.attachments[type].length > this.attachmentsToShow[type].min &&
                    this.attachmentsToShow[type].minimized === minimized;
        },

        createImageDownloadLink: function createImageDownloadLink(file) {
            if(file.cloudinary_public_id !== null || file.url.includes('cloudinary.com'))  {
                var result = file.url.split('/upload/');
                return result.join('/upload/fl_attachment/');
            } else {
                return file.url;
            }
        },

        // lightbox test
        onLightboxShown: function onLightboxShown(event){
            if (event.container === this.lightboxContainer) {
                this.AnalyticsService.track(this, 'empty_state--how_it_looks',{page: 'workspace_files', action: 'opened'});
            }
        },

        onLightboxHidden: function onLightboxHidden(event){
            if (event.container === this.lightboxContainer) {
                this.AnalyticsService.track(this, 'empty_state--how_it_looks',{stage: 'workspace_files', action: 'closed'});
            }
        },

        onLightboxImageChanged: function onLightboxImageChanged(event){
            if (event.container === this.lightboxContainer) {
                this.AnalyticsService.track(this, 'empty_state--how_it_looks',{stage: 'workspace_files', action: 'image_changed'});
            }

        },

        seeHowItLooks: function seeHowItLooks() {
                if (!this.galleryInitialized){
                    this.JsSlicklightboxService.init(this.howItLooksImages, this.lightboxContainer);
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_HIDDEN, this.onLightboxHidden.bind(this));
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_SHOWN, this.onLightboxShown.bind(this));
                    this.JsSlicklightboxService.on(this.JsSlicklightboxService.LIGHTBOX_IMAGE_CHANGE, this.onLightboxImageChanged.bind(this));
                    this.galleryInitialized = true;
                }
                this.JsSlicklightboxService.show();
        },

        createImageDownloadName: function createDownloadSaveAsName(file) {
            return file.url.substring(decodeURIComponent(file.url).lastIndexOf('/')+1);
        },

        setImagesLoaded: function setImagesLoaded(ready) {
            if (angular.isUndefined(this.imagesAlreadyInitialized)) {
                this.imagesReady = ready;
                if (ready) {
                    this.imagesAlreadyInitialized = true;
                }
            }
        },

        _getAttachments: function _getAttachments() {

            this.attachmentsToShow = {
                files: {
                    min: 14,
                    max: 100,
                    minimized: true
                },
                images: {
                    min: 5,
                    max: 100,
                    minimized: true
                }
            };

            this.attachmentsToShow.files.limit = this.attachmentsToShow.files.min;
            this.attachmentsToShow.images.limit = this.attachmentsToShow.images.min;
            this.WorkspacesManager.getAttachments(this.workspace).then(function() {
                this.formatFiles();
            }.bind(this))
            .finally(function() {
                this.loadingAttachments = false;
            }.bind(this));
        },

        isCurrentUserCreator: function isCurrentUserCreator() {
            return this.creator && this.creator._id === this.user._id;
        },
    });
}());